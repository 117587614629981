import React, { useState, useEffect } from "react";
import { Drawer, Button, useMediaQuery, Typography } from "@material-ui/core";
import { CartList } from "./CartList";
import { useHistory, useRouteMatch } from "react-router-dom";

export const CartDrawer = ({ open, setOpen }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="left">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
          marginRight: "1rem",
        }}
      >
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            history.push(`/${match.params.restaurantId}/checkout`);
          }}
        >
          Proceed to checkout asd
        </Button>
      </div>
      <div
        style={{
          minWidth: isMobile ? "100vw" : "",
          maxWidth: "100vw",
          maxHeight: "100vh",
          overflowY: "auto",
          padding: "1rem",
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 800 }}>
          My Orders
        </Typography>
        <CartList />
      </div>
    </Drawer>
  );
};
