import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

const ProgressWithLabel = ({ value, color, label }) => {
  return (
    <Box position="relative" display="inline-flex" width="100%">
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          width: "100%",
          "& .MuiLinearProgress-bar": {
            backgroundColor: (theme) =>
              color ? color : theme.palette.primary.main,
          },
          height: "16px",
          borderRadius: 2,
        }}
      />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="white">
          {label} {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressWithLabel;
