import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";
import { Form, Input } from "../../components";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedAddress,
  setSelectedServiceOption,
} from "../../redux/publicReducer/actions";
export const SelectAddress = () => {
  const history = useHistory();
  const [addresses, setAddresses] = useState([]);
  const dispatch = useDispatch();
  const { user, selectedAddress, orderProcessingType } = useSelector(
    (state) => state.public
  );

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    address: selectedAddress ? selectedAddress.address : "",
    city: selectedAddress ? selectedAddress.city : "",
    zipCode: selectedAddress ? selectedAddress.zipCode : "",
    phone: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    zipCode: Yup.string(),
    phone: Yup.string().required("Phone number is required"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { errors, reset } = methods;

  const handleSubmit = async (d) => {
    dispatch(setSelectedAddress(d));
    history.goBack();
    reset(initialValues);
  };

  return (
    <React.Fragment>
      <IconButton onClick={() => history.goBack()}>
        <ArrowBackIcon />
      </IconButton>
      <Container style={{ position: "relative", minHeight: "90vh" }}>
        {addresses.map((add) => {
          return (
            <Box
              padding="1rem"
              borderRadius="10px"
              width="100%"
              margin="1rem 0rem"
              border="1px solid #bebebe"
              onClick={() => {
                history.goBack();
              }}
            >
              <Typography variant="subtitle1" color="primary">
                {add.address + ", " + add.city + ", " + add.zipCode}
              </Typography>
              <Typography variant="caption">{add.phone}</Typography>
            </Box>
          );
        })}
        <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
          Customer Details
        </Typography>
        {/* {errors.phone ? toast.error(errors.phone.message) : null} */}
        <Form provider={methods} onSubmit={handleSubmit}>
          <Box height="1rem" />
          <Typography>First Name</Typography>
          <Input
            variant="outlined"
            name="first_name"
            placeholder="First Name"
            defaultValue={selectedAddress ? selectedAddress.first_name : ""}
          />
          <Typography>Last Name</Typography>
          <Input
            variant="outlined"
            name="last_name"
            placeholder="Last Name"
            defaultValue={selectedAddress ? selectedAddress.last_name : ""}
          />
          <Typography>Email</Typography>
          <Input
            variant="outlined"
            name="email"
            type="email"
            placeholder="email@email.com"
            defaultValue={selectedAddress ? selectedAddress.email : ""}
          />
          <Typography>Address</Typography>
          {orderProcessingType == 2 ? selectedAddress.address : ""}
          <Input
            hidden={orderProcessingType == 2 ? true : false}
            variant="outlined"
            name="address"
            placeholder="Address"
            defaultValue={selectedAddress ? selectedAddress.address : ""}
          />
          <Typography>City</Typography>
          {orderProcessingType == 2 ? selectedAddress.city : ""}
          <Input
            hidden={orderProcessingType == 2 ? true : false}
            variant="outlined"
            name="city"
            placeholder="City"
            defaultValue={selectedAddress ? selectedAddress.city : ""}
          />
          <Typography>Zip Code</Typography>
          {orderProcessingType == 2 ? selectedAddress.zipCode : ""}
          <Input
            hidden={orderProcessingType == 2 ? true : false}
            name="zipCode"
            variant="outlined"
            placeholder="Zip Code"
            defaultValue={selectedAddress ? selectedAddress.zipCode : ""}
          />
          <Box height="6px" />
          <Typography gutterBottom>Mobile #</Typography>
          <Box height="6px" />
          <Controller
            as={
              <PhoneInput
                defaultCountry="US"
                inputClassName="customPhoneInput"
                defaultValue={selectedAddress ? selectedAddress.phone : ""}
              />
            }
            name="phone"
          />
          <Box bottom="0px" left="0px" marginTop="2rem" width="100%">
            <Button
              type="submit"
              fullWidth
              elevation={0}
              style={{
                textTransform: "none",
                padding: "9px 0rem",
                borderRadius: "10px",
                border: "1px solid #bebebe",
                backgroundColor: "white",
                fontSize: "1.1rem",
              }}
              variant="contained"
            >
              Save Details
            </Button>
          </Box>
        </Form>
      </Container>
    </React.Fragment>
  );
};
