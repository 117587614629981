import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { setLoading } from "../../../redux/publicReducer/actions";
import { setOrderSession } from "../../../redux/cartReducer/actions";
import { giftcardService } from "../../../services";

export const GiftCardAsPayment = ({ setOpen }) => {
  const { selectedAddress, orderProcessingDate, orderProcessingType } =
    useSelector((state) => state.public);
  const {
    cartItems,
    tips,
    selectedPayment,
    totalPriceItems,
    orderSession,
    promoCode,
    giftCardBalance,
  } = useSelector((state) => state.cart);

  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const [buttonLabel, setButtonLabel] = useState("Check Balance");
  const [giftCardInfo, setGiftCardInfo] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onPayment = () => {
    // Payment logic here
  };

  const onSubmit = (data) => {
    // Submit logic here
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          ${giftCardBalance}
        </Typography>

        <form
          id="giftcardForm"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {/* Form content */}
        </form>

        {/* Additional content */}
      </div>
      <Box mt={5}></Box>
    </Container>
  );
};
