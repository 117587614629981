import React, { useEffect, useState } from "react";
import config from "../../../config";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import AOS from "aos";
import "aos/dist/aos.css";

const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;

export const AboutSlider = () => {
  const [aboutContent, setaboutContent] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  useEffect(() => {
    // Lazy load the image
    const img = new Image();
    img.src = siteMedSrc + "about_us_bg.webp";
    img.onload = () => {
      document.querySelector(
        ".HeaderImg"
      ).style.backgroundImage = `url(${img.src})`;
    };
  }, []);

  useEffect(() => {
    const aboutContentText = config.aboutcontent;

    setaboutContent(aboutContentText);
  }, []);

  return (
    <Grid
      container
      className="HeaderImg page-info"
      data-aos-easing="ease-in-out"
      data-aos-delay="50"
      data-aos="fade-top"
      style={{
        backgroundImage: `url(${siteMedSrc}about_us_bg.webp)`,
        color: buttonText,
      }}
    >
      <Grid
        item
        md={12}
        className="whitespace"
        data-aos-easing="ease-in-out"
        data-aos-delay="100"
      >
        <Box
          className="page-info-right aos-init aos-animate"
          data-aos="fade-left"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
        >
          <Typography
            variant="h3"
            gutterBottom
            className="left"
            data-aos-easing="ease-in-out"
            data-aos-delay="300"
            style={{
              color: textHighlight,
            }}
          >
            {aboutContent.title}
          </Typography>
          <Typography
            paragraph
            className="white"
            data-aos-easing="ease-in-out"
            data-aos-delay="400"
          >
            {aboutContent.subtitle}
          </Typography>
          <Typography
            paragraph
            className="white"
            data-aos-easing="ease-in-out"
            data-aos-delay="500"
          >
            {aboutContent.description}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
