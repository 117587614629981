import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ModalVideo from "react-modal-video";
import Card from "@material-ui/core/Card";
import Box from "@mui/material/Box";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "react-modal-video/scss/modal-video.scss";

const showImage = process.env.REACT_APP_SHOW_IMAGE_SRC;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const site = process.env.REACT_APP_SITE;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
let enableItemVideo = process.env.REACT_APP_ITEM_VIDEO;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const enableRevenueCenter = parseInt(
  process.env.REACT_APP_ENABLE_REVENUE_CENTER
);

export default function FoodCard(props) {
  const isMobile = useMediaQuery("(max-width:600px)");
  let current_url = window.location.href;
  current_url = current_url.split("/");
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      margin: theme.spacing(2),
      height: enableKiosk === "0" ? "auto" : "190px",
      height: "auto",
      position: "relative",
      padding: {
        sm: 0,
      },
      backgroundColor: "white",
      cursor: "pointer",
      borderBottom: isMobile && "2px solid #f2f2f2",
      border: !isMobile && "2px solid #f2f2f2",
      borderRadius: "8px",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: isMobile ? "100%" : "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      webkitLineClamp: "3" /* number of lines to show */,
      webkitBoxOrient: "vertical",
    },
    price: {
      position: "absolute",
      bottom: "5px",
      color: "black",
    },
    content: {
      flex: "0 1 auto",
      width: "100%",
      wordWrap: "break-word",
      overflow: "hidden",
      lineClamp: 2,
    },
    cover: {
      width: isMobile ? "250px" : enableKiosk === "0" ? "220px" : "195px",
      position: "relative",
    },

    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    playIcon: {
      height: enableKiosk === "0" ? "50" : "38",
      width: enableKiosk === "0" ? "50" : "38",
    },
  }));

  const handleItemClicked = () => {
    props.getSelectedProduct(props.product.ItemID);
    if (enableRevenueCenter === 1) {
      const productBaseUrl =
        current_url[3] !== "menu" ? props.categoryName : "menu";
      props.getSelectedProduct(props.product.ItemID);

      history.push({
        pathname:
          "/" +
          productBaseUrl +
          "/" +
          props.product.ItemName.replace(/[^a-zA-Z0-9]+/g, "-"),
        state: {
          revenueCenter: props.revenueCenter,
          menuName: props.menuName,
        },
      });
    } else {
      history.push(
        match.params.category
          ? `/menu/${match.params.category}/${props.product.ItemName.replace(
              /[^a-zA-Z0-9]+/g,
              "-"
            )}`
          : `/menu/${props.product.ItemName.replace(/[^a-zA-Z0-9]+/g, "-")}`
      );
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const [isItemAvailable, setIsItemAvailable] = useState(true);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Card
        style={{
          display: "flex",
          height: enableKiosk === "1" ? "310px" : "175px",
        }}
        className={classes.root + " bg-white"}
        square
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={() => handleItemClicked()}
          className={classes.content}
        >
          {enableKiosk === "0" ? (
            <CardContent
              sx={{ flex: "1 0 auto", height: "100%" }}
              justifyContent="space-between"
            >
              <div>
                <Typography
                  component="div"
                  variant="h5"
                  style={{
                    fontWeight: 500,
                    lineHeight: 1.1,
                    fontSize: "1.25rem",
                    paddingBottom: ".5rem",
                  }}
                >
                  {props.product.ItemName}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  style={{
                    display: "-webkit-box",
                    "-webkit-line-clamp": "3",
                    overflow: "hidden",
                    "-webkit-box-orient": "vertical",
                    lineHeight: 1.1,
                    marginBottom: "1rem",
                  }}
                >
                  {props.product.ItemDescription
                    ? isMobile
                      ? props.product.ItemDescription.length > 50
                        ? props.product.ItemDescription.substring(0, 50 - 3) +
                          "..."
                        : props.product.ItemDescription.substring(0, 50)
                      : props.product.ItemDescription.length > 70
                        ? props.product.ItemDescription.substring(0, 70 - 3) +
                          "..."
                        : props.product.ItemDescription.substring(0, 70)
                    : null}
                </Typography>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "5px",
                  left: "15px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "8px",
                  border: "1px solid white",
                  zIndex: 1,
                }}
              >
                <div className="flexRowCompact">
                  <div className="primItemDisplay">
                    <div className="flexTruncateBaseline">
                      <Typography
                        component="div"
                        variant="h5"
                        style={{
                          fontWeight: 500,
                          lineHeight: 1.2,
                          fontSize: "1.25rem",
                        }}
                      >
                        $
                        {numberWithCommas(
                          typeof props.product.DefaultPrice === "number"
                            ? props.product.DefaultPrice / 100
                            : props.product.ActualPrice
                        )}
                      </Typography>
                    </div>
                  </div>
                  {parseFloat(props.product.DiscountTotal) > 0 && (
                    <div>
                      {/* <div className="discountTag white">
                        <span aria-label={`-${props.product.Discount}%`}>
                          -{props.product.Discount}%
                        </span>
                      </div> */}
                      <div className="origPriceTagFC">
                        <div className="flexTruncateBaseline">
                          <span>$</span>
                          <span className="truncate">
                            {props.product.DefaultPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          ) : (
            <CardContent className={classes.content}>
              <Stack justifyContent="space-around" width="195px">
                {showImage == 1 && (
                  <CardMedia
                    className={classes.cover}
                    title={props.product.ItemName}
                    style={{
                      padding: "0",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        src={
                          props.product.FileName &&
                          props.product.FileName.length > 0
                            ? `${siteImgSrc}${encodeURIComponent(
                                props.product.FileName
                              )}`
                            : showImage === 0
                              ? null
                              : `${siteMedSrc}empty-img.png`
                        }
                        style={{
                          borderRadius: "4px",
                          paddingLeft: "auto",
                          paddingRight: "auto", // Corrected to paddingRight instead of PaddingRight
                        }}
                        onError={(error) => {
                          // Replace broken image
                          error.target.src =
                            showImage === 0
                              ? null
                              : `${siteMedSrc}empty-img.png`;
                        }}
                        alt={props.product.ItemName}
                      />
                      <span
                        className="foodCardPlusSign"
                        style={{
                          background: "#61030C",
                          color: "white",
                          position: "absolute",
                          top: "165px",
                          left: "165px",
                          width: "40px", // Corrected width to have the correct unit
                          padding: "0 2px",
                          height: "40px",
                          display: "grid",
                          placeItems: "center",
                          borderRadius: "8px",
                          border: "1px solid white",
                        }}
                      >
                        <FaPlus fontSize="2rem" />
                      </span>
                    </div>
                  </CardMedia>
                )}
                <div>
                  <Typography
                    component="div"
                    variant="h5"
                    style={{
                      fontWeight: 500,
                      marginTop: "1rem",
                      lineHeight: 1.2,
                      display: "-webkit-box",
                      WebkitLineClamp: "1", // Corrected to camelCase
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical", // Corrected to camelCase
                    }}
                  >
                    {props.product.ItemName}
                  </Typography>
                </div>
                <div>
                  <h2 style={{ fontWeight: 600 }}>
                    $ {numberWithCommas(props.product.DefaultPrice / 100)}
                  </h2>
                </div>
              </Stack>
            </CardContent>
          )}
        </Box>
        {enableKiosk === "0" ? (
          <CardMedia className={classes.cover} title={props.product.ItemName}>
            <img
              onClick={() => handleItemClicked()}
              src={
                props.product.FileName && props.product.FileName.length > 0
                  ? siteImgSrc + encodeURIComponent(props.product.FileName)
                  : siteMedSrc + "empty-img.png"
              }
              style={{
                borderRadius: "4px",
              }}
              onError={(error) => {
                //replacement of broken Image
                error.target.src = siteMedSrc + "empty-img.png";
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
              <ModalVideo
                channel="youtube"
                youtube={{ mute: 0, autoplay: 0, controls: 1 }}
                isOpen={isOpen}
                videoId="0B1pBhtI6vA"
                onClose={() => setOpen(false)}
              />

              {enableItemVideo == 1 ? (
                <IconButton
                  aria-label="play/pause"
                  style={{
                    background: "#cc3333",
                    color: "white",
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "8px",
                    border: "1px solid white",
                    zIndex: 1,
                  }}
                  onClick={() => setOpen(true)}
                >
                  <PlayArrowIcon sx={{ height: 9, width: 9 }} />
                </IconButton>
              ) : null}

              <IconButton
                aria-label="add"
                style={{
                  background: "#cc3333",
                  color: "white",
                  position: "absolute",
                  bottom: "2px",
                  right: "2px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "8px",
                  border: "1px solid white",
                  zIndex: 1,
                }}
                onClick={() => handleItemClicked()}
              >
                <FaPlus
                  style={{ fontSize: enableKiosk === "0" ? ".5rem" : "1rem" }}
                />
              </IconButton>
            </Box>
          </CardMedia>
        ) : null}
      </Card>
    </>
  );
}
