import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Card, Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const MeetingRoomSlider = () => {
  return (
      
<div className="OrderSlider">
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left gold">
                Have you checked out our private event room?
              </h1>
                <h5 className="white">
                {" "}
                Space for 12 people including High-speed internet for video conferencing and multi-media presentations. Great for business meetings, family gatherings, small groups, and Bible studies! For a limited time, there is no room charge when ordering food & beverage during the reserved meeting time.
              </h5>
              <h1 className="left gold whitespace">
                Call <a aria-label="Call Phone Number +1 878-257-5282" href="tel:+1 878-257-5282" className="red-link">+1 878-257-5282 (878-AJS-JAVA)</a>
              </h1> 
                <h5 className="white">
                {" "}
                For details and reservations.
              </h5>                
            </Col>
            <Col sm={0} lg={4}>
    {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
    

  );
};
