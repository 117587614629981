import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { QRDialog } from "./QrDialog";
import { NotificationBell } from "../eComm";
import { useSelector } from "react-redux";

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const backgroundColor = process.env.REACT_APP_BACKGROUND_COLOR;

export const HomeScreenSLEKiosk = () => {
  const [openCamera, setOpenCamera] = useState(false);
  const firstName = sessionStorage.getItem("firstName") || null;
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <div
        style={{ position: "fixed", top: "20px", right: "20px", zIndex: 100 }}
      >
        {user && <NotificationBell />}
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: `linear-gradient(rgba(255,255,255,.05), rgba(0,0,0,.4)), url(${siteMedSrc}homescreen-bg.webp)no-repeat center center`,
          color: "white",
          position: "fixed",
          top: "0px",
          left: "0px",
          padding: "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "1.5rem",
              }}
            >
              <img
                src={siteMedSrc + "logo" + ".webp"}
                width={logoWidth}
                alt="logo"
                style={{
                  transform: "scale(2.5)",
                  padding: "2px",
                  backgroundColor: "white",
                  borderRadius: "2px",
                }}
              />
            </div>
          </Typography>
        </div>
        <Typography
          variant={isMobile ? "p" : "h1"}
          style={{
            textAlign: "center",
            fontWeight: "400",
            paddingTop: "1rem",
            marginBottom: "-2.5rem",
            fontSize: "96px",
            maxWidth: "768px",
            textShadow: "2px 2px 2px rgba(0, 0, 0, 0.5)",
            WebkitTextStroke: ".5px rgba(0, 0, 0, 0.5)",
          }}
          color="white"
        >
          ORDER
        </Typography>
        <Typography
          variant={isMobile ? "p" : "h1"}
          style={{
            textAlign: "center",
            fontWeight: "600",
            marginBottom: "2rem",
            maxWidth: "768px",
            fontSize: "130px",
            textShadow: "2px 2px 2px rgba(0, 0, 0, 0.5)",
            WebkitTextStroke: ".75px rgba(0, 0, 0, 0.5)",
          }}
          color="white"
        >
          HERE
        </Typography>
        <div>
          <div>
            <Button
              variant="contained"
              style={{
                borderRadius: "15px",
                marginTop: "1rem",
                padding: "2rem 2rem",
                backgroundColor: "rgb(61,83,9)",
                color: "white",
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: "500",
              }}
              onClick={() => {
                sessionStorage.setItem("isResident", 1);
                history.push("/sign-in-sl");
              }}
            >
              Resident | Employee
              <div
                className={`pulse`}
                style={{
                  background: "#ffff",
                  borderRadius: 0,
                  height: "105%",
                }}
              ></div>
            </Button>
            <Button
              variant="contained"
              style={{
                borderRadius: "15px",
                marginTop: "1rem",
                padding: "2rem 2rem",
                backgroundColor: "rgb(105,56,0)",
                color: "white",
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: "500",
              }}
              onClick={() => {
                sessionStorage.setItem("isResident", 0);
                history.push("/menu");
              }}
            >
              Visitor
              <div
                className={`pulse`}
                style={{
                  background: "#ffff",
                  borderRadius: 0,
                  height: "105%",
                }}
              ></div>
            </Button>
          </div>
          {/* <button
            className="MuiButton-root "
            style={{
              borderRadius: "15px!important",
              marginTop: "1rem",
              padding: "1rem 2rem",
              width: "100%",
              border: "1px solid #FF007F",
              background: "#FF007F",
              color: "#00000 ! important",
              position: "relative",
            }}
            onClick={() => {
              history.replace("/askme", { from: history.location.pathname });
            }}
          >
            <div
              style={{
                color: "#ffff",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              Ruby AI
            </div>
            <div
              className={`pulse`}
              style={{
                background: "#ffff",
                borderRadius: 0,
                height: "110%",
              }}
            ></div>
          </button> */}
        </div>
      </div>
    </>
  );
};
