export const SET_CART = "SET_CART";
export const SET_TOTAL_PRICE = "SET_TOTAL_PRICE";
export const SET_TIP = "SET_TIP";
export const SET_SELECTED_MODE_PAYMENT =
  "SET_SELECTED_MODE_PAYMENT"; /** CreditCard, GiftCard, Rewards **/
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const SET_TOTAL_DISCOUNT = "SET_TOTAL_DISCOUNT";
export const SET_ORDER_SESSION = "SET_ORDER_SESSION";
export const SET_PAYMENT_OPTIONS =
  "SET_PAYMENT_OPTIONS"; /** Full payment, Installment, Partial Payment **/
export const SET_REWARDS_BALANCE = "SET_REWARDS_BALANCE";
export const SET_GIFT_CARD_BALANCE = "SET_GIFT_CARD_BALANCE";
export const REMOVE_EXPIRED_ITEMS = "REMOVE_EXPIRED_ITEMS";
