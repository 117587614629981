import { CancelOutlined, CheckCircle } from "@material-ui/icons";
import {
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;

const RasaMessageInput = ({
  senderId,
  messageDetails,
  onEditMode,
  inputMessage,
  setInputMessage,
  setOnInputMode,
  getConversationDetails,
}) => {
  const [intentInput, setIntentInput] = useState(
    messageDetails.user_intent.name
  );
  const [intentExist, setIntentExist] = useState(false);
  const [intentDetails, setIntentDetails] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  // TODO GET INTENT RESPONSE USING RUBY-API-SERVICE
  const handleSave = async () => {
    setDisabled(true);
    setError("");
    const body = {
      eventIndex: messageDetails.eventIndex,
      newMessage: inputMessage,
      intentIndex: messageDetails.user_intent.intentIndex,
      newIntent: intentInput,
    };
    try {
      // First we need to change the conversation answer
      await axios.put(`${rubyConvoUri}/conversations/${senderId}`, body);

      // We need to add the intent
      const { data } = await axios.post(`${rubyConvoUri}/rasa-model`, {
        intent: intentInput,
        response: inputMessage,
        question: messageDetails?.user_intent?.message,
      });
      setOnInputMode(false);
      getConversationDetails();
    } catch (error) {
      setError(error.message || "Something went wrong");
    } finally {
      setDisabled(false);
    }
  };
  return (
    <Stack width="100%" direction="row" alignItems="center" gap={2}>
      {disabled ? (
        <Box
          width={"100%"}
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack>
            <IconButton
              disabled={disabled}
              color="success"
              onClick={handleSave}
            >
              <CheckCircle />
            </IconButton>
            <IconButton
              disabled={disabled}
              onClick={() => {
                setOnInputMode(false);
                setInputMessage(messageDetails.message);
              }}
            >
              <CancelOutlined />
            </IconButton>
          </Stack>
          <Stack width="100%" gap={2}>
            <TextField
              fullWidth
              value={intentInput}
              onChange={(e) => setIntentInput(e.target.value)}
              sx={{
                border: "1px solid rgba(0,0,0,.2)",
                borderRadius: 2,
              }}
              label="Intent"
              variant="outlined"
            />
            <TextField
              fullWidth
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              multiline
              rows={2}
              sx={{
                border: "1px solid rgba(0,0,0,.2)",
                borderRadius: 2,
              }}
              label="Response"
              variant="outlined"
            />
            {error && (
              <FormHelperText
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
              >
                {error}
              </FormHelperText>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default RasaMessageInput;
