import "bootstrap/dist/css/bootstrap.min.css";
import { SignInSL } from "../eComm/components/pages/homepage/SignInSL";
import { useCookies } from "react-cookie";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

export const HomepageLandingSLEKiosk = () => {
  const [openWelcome, setOpenWelcome] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookieSICName, setCookieSICName, removeCookieSICName] = useCookies([
    "sic_name",
  ]);

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  //   useEffect(() => {
  //     if (cookieSICName) {
  //       setOpenWelcome(true);
  //     }
  //   }, []);

  const handleCloseWelcome = () => {
    setOpenWelcome(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        open={openWelcome}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Welcome Back!"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ "text-align": "center" }}
            id="alert-dialog-description"
          >
            <b>{cookieSICName.sic_name}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ "text-align": "center", display: "block" }}>
          <Button onClick={handleCloseWelcome} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div className="App">
        <SignInSL />
      </div>
    </>
  );
};
