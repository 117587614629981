import React from 'react';
import "../../../App.css";
import {
	Container,
	Row,
	Col,
} from "react-bootstrap";

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

export default function RoomsContent() {
	return (
		<Container>
			<Row>
				<Col className="col-lg-12">
					<Col xs={12} className="center whitespace">
						<h2 className="dark">Private Event Room</h2>
						<img src={siteMedSrc + "room412" + ".png"} alt="Room 412" />
					</Col>
				</Col>

				<Col className="col-lg-12">
					<Col xs={12} className="center whitespace">
						<h2 className="dark">Inquire to book your next event</h2>
						<img src={siteMedSrc + "room414" + ".png"} alt="Room 414" />
					</Col>
				</Col>
			</Row>
		</Container>
	);
}