import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Container, Row } from "react-bootstrap";
import { Link, scroller } from "react-scroll";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Grid } from "@mui/material";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const categoriesFiltered = process.env.REACT_APP_CATEGORIES_FILTERED;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;
const enableMenu = process.env.REACT_APP_ENABLE_MENU;
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export const DiscoverMenu = () => {
  const [categories, setCategories] = useState([]);
  const updateTime = "";
  const history = useHistory();
  const { state } = useLocation();
  const [menuData, setMenuData] = useState([]);
  const dispatch = useDispatch();
  const [chosenCategory, setChosenCategory] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  useEffect(() => {
    const url =
      parseInt(categoriesFiltered) === 1
        ? `${apiBaseURL}/items/categories-filtered/${serverId}/${siteId}`
        : `${apiBaseURL}/items/categories/${siteId}?server_id=${serverId}`;

    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {});
  }, [updateTime]);

  useEffect(() => {
    const getMenus = async () => {
      try {
        const { data } = await axios.get(
          `${apiBaseURL}/items/menu/${serverId}/${siteId}`
        );

        // To Be Deleted once the image added to the response API

        setMenuData(data);
      } catch (error) {}
    };

    getMenus();
  }, []);

  useEffect(() => {
    if (state?.category && categories.length) {
      setChosenCategory(state.category.DepartmentID);
      scroller.scrollTo(state.category.DepartmentID, {
        duration: 1500,
        delay: 300,
        smooth: true,
        offset: -110,
      });
    }
  }, [categories]);

  return (
    <div
      className="MainContainerImg whitespace"
      style={{
        backgroundImage: `url(${siteMedSrc}tagline_bg4.webp)`,
        color: buttonText,
      }}
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
    >
      <Container>
        <Row>
          <div className="whitespace aos-init aos-animate" data-aos="fade-top">
            <Typography xs={12}>
              <h2 className="center white">Explore Our Menu</h2>
            </Typography>
            <h3
              style={{ fontSize: "1.25rem", color: textHighlight }}
              className="whitespace center"
            >
              Indulge in a culinary journey at {restaurantName} and explore our
              exquisite menu, crafted to tantalize your taste buds.
            </h3>
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="aos-init aos-animate"
            data-aos="fade-bottom"
          >
            {enableMenu === "1" ? (
              <div
                style={{
                  width: "100%",
                  maxWidth: "1200px",
                  margin: "0 auto",
                  padding: "16px",
                }}
              >
                <Grid
                  container
                  spacing={{
                    xs: 2,
                    md: 4,
                  }}
                  columns={{ xs: 2, sm: 4, lg: 16 }}
                >
                  {menuData.map((menu, index) => (
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      lg={4}
                      key={index}
                      sx={{
                        cursor: "pointer",
                      }}
                      className="main-menus"
                      onClick={() => {
                        history.push(
                          "/menu/" + menu.MenuName.replace(/ /g, "-")
                        );
                        window.scrollTo(0, 0);
                      }}
                    >
                      <a
                        href={`/menu/${menu.MenuName.replace(/ /g, "-")}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              borderRadius: "4px",
                              transition: "all 1.5s",
                            }}
                            src={
                              siteMedSrc +
                              menu.MenuName.replace(/ /g, "-")
                                .replace(",", "")
                                .toLowerCase() +
                              ".webp"
                            }
                            alt={restaurantName / menu.MenuName}
                            width="300"
                            height="200"
                            loading="lazy"
                            onError={(error) =>
                              (error.target.src = siteMedSrc + "empty-img.webp")
                            }
                          />
                        </div>
                        <div
                          style={{
                            marginBottom: "40px",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              lineHeight: 1.1,
                              fontSize: "1.5rem",
                              marginTop: "1rem",
                            }}
                          >
                            {menu.MenuName}
                          </Typography>
                        </div>
                      </a>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : (
              <Tabs variant="scrollable" className="DiscoverList" width="250px">
                {categories.map((item) => (
                  <li
                    key={item.DepartmentID}
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <Link
                      href={`/menu`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "250px",
                          height: "auto",
                        }}
                      >
                        <img
                          focusRipple
                          src={siteMedSrc + item.DepartmentID + ".webp"}
                          style={{
                            width: "100%",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          width="250"
                          height="auto"
                          loading="lazy"
                          onClick={() => {
                            history.push(`/menu`);
                            window.scrollTo(0, 0);
                          }}
                          alt={item.DepartmentID / item.DepartmentDescription}
                        />
                        <Button
                          style={{
                            backgroundColor: buttonColor,
                            color: buttonText,
                            borderColor: buttonColor,
                            cursor: "pointer",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          onClick={() => {
                            history.push(`/menu`);
                            window.scrollTo(0, 0);
                          }}
                        >
                          {item.DepartmentDescription}
                        </Button>
                      </div>
                    </Link>
                  </li>
                ))}
              </Tabs>
            )}
          </Box>
        </Row>
      </Container>
    </div>
  );
};
