import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AOS from "aos";
import "aos/dist/aos.css";
import config from "../../../config";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

export const AboutBusiness = () => {
  const [aboutBusiness, setAboutBusiness] = useState(
    config.aboutbusiness || {}
  );
  const [aboutContent, setAboutContent] = useState(config.aboutcontent || {});

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const aboutImgSrc = useMemo(() => `${siteMedSrc}about-img-01.webp`, []);

  return (
    <Box data-aos-easing="ease-in-out" data-aos-delay="0">
      <Box className="page-info-right container">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} data-aos="fade-right">
            <Box>
              <Typography variant="h4" gutterBottom>
                {aboutContent.header}
              </Typography>
              <Typography variant="body1" paragraph align="justify">
                {aboutContent.message}
              </Typography>
              <Typography variant="h4" gutterBottom>
                About the Business
              </Typography>
              {aboutBusiness &&
                Object.values(aboutBusiness).map((paragraph, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    paragraph
                    align="justify"
                  >
                    {paragraph}
                  </Typography>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} data-aos="fade-left">
            <Box className="center">
              <img
                width="400"
                height="600"
                title={`${restaurantName} / ${aboutContent.header}`}
                src={aboutImgSrc}
                alt={`${restaurantName} About the Business`}
                loading="lazy"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
