import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";

export default function AccountForm(props) {
  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    if (mail) {
      Swal.fire({
        title: "Email Validation",
        text: "Invalid Email Address '" + mail + "'.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }

    return false;
  };

  return (
    <React.Fragment>
      <Container className="formBG" style={{ padding: "0" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          variant="outlined"
          padding="0"
        >
          <TextField
            onChange={(e) => {
              props.getFirstName(e.target.value);
            }}
            value={props.firstName}
            margin="dense"
            required
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            autoComplete="true"
          />
          <TextField
            onChange={(e) => {
              props.getLastName(e.target.value);
            }}
            value={props.lastName}
            margin="dense"
            required
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="true"
          />
          <TextField
            onChange={(e) => {
              props.getEmail(e.target.value);
            }}
            onBlur={(e) => {
              if (!validateEmail(e.target.value)) {
                props.getEmail("");
                e.target.value = "";
              }
            }}
            value={props.email}
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="true"
          />
          <TextField
            onChange={(e) => {
              props.getCEmail(e.target.value);
            }}
            value={props.cemail}
            margin="dense"
            required
            fullWidth
            id="confirmemail"
            label="Confirm Email Address"
            name="confirmemail"
            autoComplete="true"
          />
        </Box>
      </Container>
    </React.Fragment>
  );
}
