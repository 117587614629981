import "../../../App.css";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { orange } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function KitchenManagerCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 767 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: orange[800] }} aria-label="kitchen-manager">
            KM
          </Avatar>
        }
        className="left"  
        title="Kitchen Manager"
      />
      <CardMedia
        component="img"
        width="100%"
        height="194"
        image="https://d1ralsognjng37.cloudfront.net/30158b92-be53-416c-b2dd-2a0673a46a86.jpeg"
        alt="Kitchen Manager"
        title="Kitchen Manager"
        loading="lazy"
      />
      <CardContent>
        <Typography variant="body1" color="text.primary">
Responsible for all kitchen functions including food purchasing, preparation and maintenance of quality standards, sanitation and cleanliness, training of employees in methods of cooking, preparation, plate presentation, portion, and cost control.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button size="large" aria-label="large button" variant="contained">Apply Now</Button>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h5" paragraph left>Job Title: Kitchen Manager</Typography>
          <Typography variant="h6" paragraph left>
            Reports to: Restaurant Manager
          </Typography>
          <Typography variant="body1" paragraph left>
            Job Summary: Responsible for all kitchen functions including food purchasing, preparation and maintenance of quality standards, sanitation and cleanliness, training of employees in methods of cooking, preparation, plate presentation, portion, and cost control.
          </Typography>
          <Typography variant="h5" paragraph left>
            Activities & Responsibilities
          </Typography>
            <List>
                <ListItem>
                    Promote, work, and act in a manner consistent with the mission of AJ's Keto Factory: To serve outstanding food and provide exceptional customer service by treating every transaction as the most important of the day. To treat our customers like friends and family members. To listen intently to our customers and provide them with great value and a satisfying experience each and every time. To provide an atmosphere where customers feel welcomed and appreciated, a place that is easy to recommend on social media and by word of mouth.
                </ListItem>
                <ListItem>
                    Responsible for maintaining cleaning schedules for kitchen floors, walls, hoods, other equipment and food storage areas.
                </ListItem>
                <ListItem>
                    Monitor sanitation practices to ensure that employees follow regulations.
                </ListItem>
                <ListItem>
                    Check the quality of raw or cooked food products to ensure that proper cooking standards are met.
                </ListItem>
                <ListItem>
                    Check and maintain proper food holding and refrigeration temperature controls.
                </ListItem>
                <ListItem>
                    Estimate amounts and costs of required supplies, like food and ingredients.
                </ListItem>
                <ListItem>
                    Control food cost and usage by following proper ordering of products from storage areas, product storage procedures, standard recipes and waste control procedures.
                </ListItem>
                <ListItem>
                    Instruct cooks or other workers in the preparation, cooking, and presentation of food.
                </ListItem>
                <ListItem>
                    Work with restaurant managers to establish portion sizes and prepare standard recipe cards for all new menu items.
                </ListItem>
                <ListItem>
                    Supervise activities of cooks or workers engaged in food preparation.
                </ListItem>
                <ListItem>
                    Ensure that all food and products are consistently prepared and served according to the restaurant’s recipes, portioning, cooking and serving instructions.
                </ListItem>
                <ListItem>
                    Schedule personnel accordingly.
                </ListItem>
                <ListItem>
                    Check supplies and equipment to ensure all machines are in proper working condition.
                </ListItem>    
                <ListItem>
                    Order food, equipment or other supplies needed to ensure proper operations.
                </ListItem>
                <ListItem>
                    Ensure that products are ordered according to product specifications and received in correct count and condition.
                </ListItem>
                <ListItem>
                    Ensure that deliveries accurate and do include damaged or rotten products.
                </ListItem>
                <ListItem>
                    Check the quantity and quality of delivered products.
                </ListItem>
                <ListItem>
                    Prepare and cook foods of all types, either on a regular basis or for special guests or functions.
                </ListItem>
                <ListItem>
                    Oversee and ensure that restaurant policies regarding personnel are followed, and administer prompt, fair and consistent corrective action for any violations of company policies, rules, and procedures.
                </ListItem>                
                <ListItem>
                    Provide company and department rules, policies and procedures to new kitchen members.
                </ListItem>
                <ListItem>
                    Oversee the training of kitchen employees on kitchen equipment, utensils, cleanliness, sanitation practices, firstaid, and proper lifting posture.
                </ListItem>
                <ListItem>
                    Collaborate with other personnel to plan and develop recipes accounting for such factors as seasonal availability of ingredients or the likely number of guests.
                </ListItem>
                <ListItem>
                    Keep equipment clean and ensure proper sanitation.
                </ListItem>
                <ListItem>
                    Prepare all required paperwork, including forms, reports and schedules in an organized and mely manner.
                </ListItem>
                <ListItem>
                    Attend all scheduled employee mee ngs and offers sugges ons for improvement.
                </ListItem> 
                <ListItem>
                    Coordinate with and assist fellow employees to meet guests’ needs and support the opera on.
                </ListItem>
                <ListItem>
                    Fill-in for fellow employees to ensure guest service standards and company opera ons are maintained.
                </ListItem>    
            </List>
          <Typography variant="h5" paragraph left>
            Tools & Technology (examples in parentheses)
          </Typography>
            <List>
                <ListItem>
                    Credit card processing machines
                </ListItem>
                <ListItem>
                    Laser Soda Gun
                </ListItem>
                <ListItem>
                    Coffee machines
                </ListItem>
                <ListItem>
                    Ice dispensers
                </ListItem>
                <ListItem>
                    Ice-making machines
                </ListItem>
                <ListItem>
                    Intercom systems
                </ListItem>
                <ListItem>
                    Non-carbonated beverage dispenser
                </ListItem>
                <ListItem>
                    Personal computers
                </ListItem>
                <ListItem>
                    Pocket calculator
                </ListItem>
                <ListItem>
                    Point-of-sale terminals and worksta ons
                </ListItem>
                <ListItem>
                    Touch screen monitors
                </ListItem>
                <ListItem>
                    Data base user interface and query so ware
                </ListItem>
            </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}