import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function FormRow() {
  return (
    <React.Fragment>
      {/* <Grid item xs={4}>
        <Box
          sx={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            color: "white",
            height: "170",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundImage: `url(${siteMedSrc}credit-card.png)`,
            "&:hover": {
              backgroundColor: "dark.main",
              opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Gift Cards
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            color: "white",
            height: "170",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundImage: `url(${siteMedSrc}gift-card.png)`,
            "&:hover": {
              backgroundColor: "dark.main",
              opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Gift Cards
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            color: "white",
            height: "170",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundImage: `url(${siteMedSrc}rewards-card.png)`,
            "&:hover": {
              backgroundColor: "dark.main",
              opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          Rewards
        </Box>
      </Grid> */}
    </React.Fragment>
  );
}

export default function PaymentNested() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid container item spacing={2}>
          <FormRow />
        </Grid>
      </Grid>
    </Box>
  );
}
