import React, { useEffect } from "react";
import "../../../App.css";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import AppBar from "./components/AppBar";
import FixedBottomNavigation from "./components/FixedBottomNavigation";
import RewardsTabs from "./components/RewardsTabs";

export const Rewards = () => {

  useEffect(() => {
    document.title = "Rewards";
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const btnstyle = { margin: "4px 0" };
  return [<AppBar />, <RewardsTabs />, <FixedBottomNavigation />];
};

export default withRouter(Rewards);
