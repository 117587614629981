import "../../../App.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoCenter from "../components/LogoCenter";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useRouteMatch, withRouter } from "react-router-dom";
import BlankSpace from "../components/BlankSpace";
import BottomNav from "../components/BottomNav";
import ScrollToDown from "components/scroll/ScrollToDown";
//import ChangeTextSize from "components/changeText/ChangeTextSize";
import DrawerRight from "../components/DrawerRight";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const current_url = window.location.href;
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export const WelcomeQr = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  console.log(match);
  const [servingPeriod, setServingPeriod] = useState([]);
  const [revenueCenter, setRevenueCenter] = useState([]);
  const [menuName, setMenuName] = useState([]);
  const [selectedRevenueCenter, setSelectedRevenueCenter] = useState(
    props.match.params.revenueCenterName.replace("-", " ")
  );
  const [selectedServingPeriod, setSelectedServingPeriod] = useState("none");
  const [selectedMenuName, setSelectedMenuName] = useState("none");

  const urlParams = new URLSearchParams(window.location.search);
  const table = urlParams.get("table");
  sessionStorage.setItem("table", table);

  useEffect(() => {
    document.title = "Welcome";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("pinCodeOk") !== "1") {
      sessionStorage.setItem("categoryUrl", current_url);
      window.location.href = "/";
    }
  }, []);

  //Get Revenue Center or Serving Period
  useEffect(() => {
    const url =
      enableRevenueCenter == 1
        ? apiBaseURL + "/items/revenue-center/" + serverId + "/" + siteId
        : apiBaseURL +
          "/items/serving-period/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId;
    axios
      .get(url)
      .then((response) => {
        if (enableRevenueCenter == 1) {
          setRevenueCenter(response.data);
        } else {
          setServingPeriod(response.data);
        }
      })
      .catch((error) => {});
  }, []);

  //Get Revenue Center Menu
  useEffect(() => {
    const url =
      apiBaseURL +
      "/items/revenue-menu/" +
      serverId +
      "/" +
      siteId +
      "/" +
      selectedRevenueCenter;
    if (selectedRevenueCenter != "none") {
      axios
        .get(url)
        .then((response) => {
          if (response.data != "") {
            setMenuName(response.data);
          } else {
            history.push({
              pathname: "/menu",
              state: {
                servingPeriod: selectedServingPeriod,
                revenueCenter: selectedRevenueCenter,
                menuName: selectedMenuName,
              },
            });
          }
        })
        .catch((error) => {});
    }
  }, [selectedRevenueCenter]);

  return (
    <div className="Welcome">
      <LogoCenter />
      <BlankSpace />
      <DrawerRight />
      {/*<Container>
        <Row>
          <div className="center">
            <div className="f-title">
              <h4>
                Good day <b>Shirley</b>. Your <b>Meal Credit Available</b> is
                <Typography variant="h1" component="div" align="center">
                  {" "}
                  30{" "}
                </Typography>
              </h4>
            </div>
          </div>
          <BlankSpace />
        </Row>
      </Container>*/}

      <Container fluid="md" className="welcomeCustom">
        <Row>
          {/*<Col xs={12} sm={4} className="blankspace center">
            <Card>
              <CardActionArea href="/menu">
                <CardMedia
                  component="img"
                  height="140"
                  image="/static/breakfast.webp"
                  alt="Breakfast"
                />
                <CardContent sx={{ paddingTop: 0 }}>
                  <Typography gutterBottom variant="h4" component="div">
                    Breakfast
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card> 
          </Col>*/}
          {selectedRevenueCenter != "none" ? (
            <IconButton
              onClick={() => setSelectedRevenueCenter("none")}
              id="nohover"
              disableautofocus
              disableenforcefocus
            >
              <ArrowBackIcon /> Back
            </IconButton>
          ) : null}
          {enableRevenueCenter == 1 && selectedRevenueCenter == "none"
            ? revenueCenter.map((item) => {
                return (
                  <>
                    <Col xs={12} sm={4} className="blankspace center">
                      <Card>
                        <CardActionArea
                          onClick={() => {
                            setSelectedRevenueCenter(item.RevenueName);
                            // history.push({
                            //   pathname: "/menu",
                            //   state: {
                            //     servingPeriod: selectedServingPeriod,
                            //     revenueCenter: item.RevenueName,
                            //   },
                            // });
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            image={"/static/" + item.RevenueName + ".webp"}
                            alt={item.RevenueName}
                          />
                          <CardContent sx={{ paddingTop: 0 }}>
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="div"
                            >
                              {item.RevenueName}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Col>
                  </>
                );
              })
            : menuName.map((item) => {
                return (
                  <>
                    <Col xs={12} sm={4} className="blankspace center">
                      <Card>
                        <CardActionArea
                          onClick={() => {
                            setSelectedMenuName(item.MenuName);
                            history.push({
                              pathname: "/menu",
                              state: {
                                servingPeriod: selectedServingPeriod,
                                revenueCenter: selectedRevenueCenter,
                                menuName: item.MenuName,
                              },
                            });
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            image={"/static/" + item.MenuName + ".webp"}
                            alt={item.RevenueName}
                          />
                          <CardContent sx={{ paddingTop: 0 }}>
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="div"
                            >
                              {item.PeriodName
                                ? item.PeriodName
                                : item.ServingPeriodName
                                  ? item.ServingPeriodName
                                  : item.MenuName}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Col>
                  </>
                );
              })}
        </Row>
      </Container>
      <BlankSpace />
      <BlankSpace />
      <BottomNav sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} />
      {/* <ChangeTextSize /> */}
      <ScrollToDown />
    </div>
  );
};
