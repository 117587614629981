import { useEffect, useRef, useState } from "react";
import {
  AudioConfig,
  SpeechConfig,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";

const useTts = (defaultText) => {
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(defaultText);
  useEffect(() => {
    if (!text) return;
    const speechConfig = SpeechConfig.fromSubscription(
      "061979f756dc4e8c9280619317e3283f",
      "centralus"
    );

    speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
    const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
    const newSynthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
    newSynthesizer.speakTextAsync(
      text,
      (result) => {
        if (result.errorDetails) {
          console.error(result.errorDetails);
        } else {
          setDuration(Math.round(result.audioDuration / 10000));
        }
      },
      (err) => console.log(err)
    );

    setLoading(false);
  }, [text]);

  // const stopSpeaking = () => {
  //   if (synthesizer) {
  //     synthesizer.close();
  //     setSynthesizer(null);
  //   }
  // };

  return [text, setText, loading, duration];
};

export default useTts;
