import "../../../App.css";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { orange } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FrontHouseCashierCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 767 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: orange[800] }} aria-label="front-house-cashier">
            FHC
          </Avatar>
        }
        className="left"  
        title="Front House - Cashier"
      />
      <CardMedia
        component="img"
        width="100%"
        height="194"
        image="https://d1ralsognjng37.cloudfront.net/2510165b-d64a-44e2-b739-d6788ecf5411.jpeg"
        alt="Front House - Cashier"
        title="Front House - Cashier"
        loading="lazy"
      />
      <CardContent>
        <Typography variant="body1" color="text.primary">
Provide friendly, responsive service to create an exceptional take out experience for all guests by taking orders, making recommendations when appropriate, processing payments, and serving food or beverages to customers at the counter or table. Also perform other duties in the areas of food and final order preparation including preparing beverages and arranging food items.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button size="large" aria-label="large button" variant="contained">Apply Now</Button>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h5" paragraph left>Job Title: Cashier/Counter Attendant/Beverage Station</Typography>
          <Typography variant="h6" paragraph left>
            Reports to: Restaurant Manager
          </Typography>
          <Typography variant="body1" paragraph left>
            Job Summary: Provide friendly, responsive service to create an exceptional take out experience for all guests by taking orders, making recommendations when appropriate, processing payments, and serving food or beverages to customers at the counter or table. Also perform other duties in the areas of food and final order preparation including preparing beverages and arranging food items.
          </Typography>
          <Typography variant="h5" paragraph left>
            Activities & Responsibilities
          </Typography>
          <Typography variant="h6" paragraph left>
            Primary
          </Typography>
            <List>
                <ListItem>
                    Promote, work, and act in a manner consistent with the mission of AJ Sliders: To serve outstanding food and provide exceptional customer service by treating every transaction as the most important of the day. To treat our customers like friends and family members. To listen intently to our customers and provide them with great value and a satisfying experience each and every time. To provide an atmosphere where customers feel welcomed and appreciated, a place that is easy to recommend on social media and by word of mouth.
                </ListItem>
                <ListItem>
                    Serve food, beverages, or desserts to customers.
                </ListItem>
                <ListItem>
                    Prepare bills for food and accept payment or make change.
                </ListItem>
                <ListItem>
                    Cook food or prepare food items, such as salads, and ice cream dishes, according to standard portion sizes and recipe specifications or following directions.
                </ListItem>
                <ListItem>
                    Perform cleaning duties such as sweeping, mopping, and washing dishes, to keep equipment and facilities sanitary.
                </ListItem>
                <ListItem>
                    Balance receipts and payments in cash registers.
                </ListItem>
                <ListItem>
                    Wrap menu item such as sandwiches, hot entrees, and desserts for serving or for takeout.
                </ListItem>
                <ListItem>
                    Scrub and polish counters, steam tables, and other equipment, and clean glasses, dishes, and fountain equipment.
                </ListItem>
                <ListItem>
                    Replenish foods at serving stations.
                </ListItem>
                <ListItem>
                    Brew coffee and tea, and fill containers with requested beverages.
                </ListItem>
                <ListItem>
                    Attend all scheduled employee meetings and offers suggestions for improvement.
                </ListItem>
                <ListItem>
                    Coordinate with and assist fellow employees to meet guests’ needs and support the operation of the restaurant.
                </ListItem>
                <ListItem>
                    Fill-in for fellow employees where needed to ensure guest service standards and efficient operations.
                </ListItem>
            </List>
          <Typography variant="h6" paragraph left>
            Secondary
          </Typography>
            <List>
                <ListItem>
                    Take guests’ orders and write ordered items on tickets, giving ticket stubs to customers when needed to identify filled orders.
                </ListItem>
                <ListItem>
                    Order items needed to replenish supplies.
                </ListItem>
                <ListItem>
                    Serve salads, vegetables, meat, breads, and cocktails, ladle soups and sauces, portion desserts, and fill beverage cups and glasses.
                </ListItem>
                <ListItem>
                    Set up dining areas for meals and clear them following meals.
                </ListItem>
                <ListItem>
                    Carve meat.
                </ListItem>
                <ListItem>
                    Deliver orders to kitchens and pick up and serve food when it is ready.
                </ListItem>
                <ListItem>
                    Add relishes and garnishes to food orders, according to instructions.
                </ListItem>
                <ListItem>
                    Arrange reservations for patrons of dining establishments.
                </ListItem>
            </List>
          <Typography variant="h5" paragraph left>
            Tools & Technology (examples in parentheses)
          </Typography>
            <List>
                <ListItem>
                    Credit card processing machines
                </ListItem>
                <ListItem>
                    Laser Soda Gun
                </ListItem>
                <ListItem>
                    Coffee machines
                </ListItem>
                <ListItem>
                    Ice dispensers
                </ListItem>
                <ListItem>
                    Ice-making machines
                </ListItem>
                <ListItem>
                    Intercom systems
                </ListItem>
                <ListItem>
                    Non-carbonated beverage dispenser
                </ListItem>
                <ListItem>
                    Personal computers
                </ListItem>
                <ListItem>
                    Pocket calculator
                </ListItem>
                <ListItem>
                    Point-of-sale terminals and worksta ons
                </ListItem>
                <ListItem>
                    Touch screen monitors
                </ListItem>
                <ListItem>
                    Data base user interface and query so ware
                </ListItem>
            </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}
