import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function BlankSpaceTen() {
  return (
    <Box sx={{ height: '10%', maxHeight: 50 }}>
      <Typography gutterBottom>
      </Typography>
    </Box>
  );
}