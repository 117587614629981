import "../../../App.css";
import { Container, Row, Col, h5, p, h2, div } from "react-bootstrap";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import KitchenManagerCard from "../components/KitchenManagerCard";
import KitchenStaffCard from "../components/KitchenStaffCard";
import CardMediaJoin from "../components/CardMediaJoin";
import BlankSpace from "../components/BlankSpace";
import FrontHouseCashierCard from "../components/FrontHouseCashierCard";
import { Footer } from "../components/Footer";
import { TeamSlider } from "../components/TeamSlider";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Team = () => {
  useEffect(() => {
    document.title = "Join Our Growing Team";
  }, []);

  return (
    <div className="Team">
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {"Team: We are a team who seeks direction from the Holy Spirit"}
          </title>
          <link rel="canonical" href="/team" />
          <meta
            name="description"
            content="Lead by leaders dedicated to using our time, talent, and money to meet the community's physical and spiritual needs to advance God's purpose.."
          />
        </Helmet>
      </HelmetProvider>
      <TeamSlider />
      <Container>
        <Row>
          <Col xs={12} className="whitespace">
            <h2 className="left dark">Step 1, watch this short video</h2>
          </Col>
        </Row>
      </Container>
      <Col xs={11} sm={10} md={6} className="center">
        <CardMediaJoin />
      </Col>
      <Container>
        <Row>
          <Col xs={12} className="whitespace ">
            <h2 className="left dark ">Step 2, select a position and apply</h2>
          </Col>
          <col className="whitespace"></col>
          <Col xs={10} className="center title">
            <h2 className=""> Job Application Form</h2>
          </Col>
          <Col xs={10} className="center title">
            <h3 className="">
              {" "}
              Please download, fill-up and attach this document.
            </h3>
          </Col>
          <Col xs={10} className="center title">
            <Button size="large" aria-label="large button" variant="contained">
              Download Here
            </Button>
          </Col>
          <col className="whitespace"></col>
        </Row>
      </Container>

      <Col xs={11} sm={10} md={6} className="center">
        <KitchenManagerCard />
      </Col>
      <Col xs={11} sm={10} md={6} className="center">
        <KitchenStaffCard />
      </Col>
      <Col xs={11} sm={10} md={6} className="center">
        <FrontHouseCashierCard />
      </Col>
      <BlankSpace />
      <Footer />
    </div>
  );
};
