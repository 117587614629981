import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function RewardsPoints() {
  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "20px" }}>
      <Item style={{ borderRadius: "20px", margin: "0 20px" }}>
        <Typography variant="h5" gutterBottom component="div" align="center">
          You need:
        </Typography>
        <Typography variant="h4" gutterBottom component="div" align="center">
          $50.00
        </Typography>
        <Typography variant="body1" gutterBottom component="div" align="center">
          In order to receive $5 in rewards dollar.
        </Typography>
      </Item>
    </Box>
  );
}
