import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  InputBase,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";

import { useRouteMatch, useHistory } from "react-router-dom";
import { setSearch } from "../../../redux/publicReducer/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
export const RestaurantSearch = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState({});
  const [loaded, setLoaded] = useState(false);

  if (!loaded) {
    return null;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <IconButton
        style={{ position: "fixed", top: "1rem", left: "1rem" }}
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon style={{ color: "white" }} />
      </IconButton>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            style={{
              width: "100%",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${restaurant.layout?.logo})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: 800,
                color: "white",
                textShadow: "3px 3px 6px black",
              }}
            >
              Search {restaurant.restaurantName}
            </Typography>
          </Grid>
          <form
            onSubmit={() => {
              history.push(`/menu/${match.params.restaurantId}`);
            }}
          >
            <Paper
              style={{
                bottom: "5vh",
                height: "10vh",
                padding: "0rem 2rem",
                position: "relative",
                minWidth: "50vw",
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <InputBase
                placeholder="Search Menu"
                onChange={(e) => dispatch(setSearch(e.target.value))}
                style={{ width: "100%" }}
              />
              <IconButton style={{ marginLeft: "auto" }} type="submit">
                <SearchIcon />
              </IconButton>
            </Paper>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};
