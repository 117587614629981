import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

export default function ButtonAppBar() {
  const history = useHistory();

  const onLogout = () => {
    Cookies.remove("sic_user_id");
    Cookies.remove("sic_email");
    Cookies.remove("sic_name");
    Cookies.remove("sic_firstName");
    Cookies.remove("connect.ec.sid");
    history.push("/login");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{ padding: "10px 0", backgroundColor: "#c33" }}
      >
        <Toolbar>
          <IconButton
            onClick={() => history.push("/")}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <CancelPresentationIcon fontSize="large" />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            style={{ textAlign: "center", marginRight: "16px" }}
          >
            View Rewards
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            style={{ marginRight: "-16px" }}
            onClick={onLogout}
          >
            <ExitToAppIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
