import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function PersonalDetails(props) {
  const [focused, setFocused] = useState(false);
  const [DOB, setDOB] = useState(new Date());
  const [anniv, setAnniv] = useState(new Date());

  return (
    <React.Fragment>
      <Container className="formBG" style={{ padding: "0" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          variant="outlined"
          padding="0"
        >
          <Typography level="body1" style={{ padding: "0 1rem 2rem 1rem" }}>
            At AJ's Java Joint, We love celebrating Birthdays and
            Anniversaries. Please share your date below so we can celebrate with
            you. You can click submit anyways, without changing those fields.
          </Typography>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ padding: "15px", width: "100%" }}
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Birthday(MM/dd) - Optional"
              format="MM/dd"
              value={props.DOB ? props.DOB : DOB}
              InputAdornmentProps={{ position: "start" }}
              onChange={(date) => {
                props.getDOB(date);
                setDOB(date);
              }}
              InputProps={{
                style: { color: props.DOB == "" ? "#f5f5db" : "#000000" },
              }}
              onClick={(e) => {
                if (props.DOB == "") {
                  e.target.value = "";
                }
              }}
            />

            <KeyboardDatePicker
              style={{ padding: "15px", width: "100%" }}
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Anniversary(MM/dd) - Optional"
              format="MM/dd"
              value={props.anniv ? props.anniv : anniv}
              InputAdornmentProps={{ position: "start" }}
              onChange={(date) => {
                props.getAnniv(date);
                setAnniv(date);
              }}
              InputProps={{
                style: { color: props.anniv == "" ? "#f5f5db" : "#000000" },
              }}
              onClick={(e) => {
                if (props.anniv == "") {
                  e.target.value = "";
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Container>
    </React.Fragment>
  );
}
