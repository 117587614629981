import { createClient } from "contentful";

const spaceId = 'wv5xn72nxxx4';
const accessId = 'FtsczcUbZQrfdI2nyvjP73TPdmfGmqiS5HU7iQe_w08';

const useContentful = () => {
  const client = createClient({
    space: spaceId,
    accessToken: accessId,
    host: "preview.contentful.com",
  });

  const getHomeView = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "home",
        select: "fields",
      });

      const sanitizedEntries = entries.items.map((item) => {
        return {
          ...item.fields,
        };
      });

      return sanitizedEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getHomeView };
};

export default useContentful;
