import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Box,
  Toolbar,
  Drawer,
  IconButton,
  ImageList,
  ImageListItem,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import { Row, Col } from "react-bootstrap";
import KioskPad from "./components/Keypad";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput from "react-phone-number-input";
import { NavBar } from "./navigation/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/numbersWithComma";
import moment from "moment";
import { Form, Input } from "../../components";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { removeExpiredItems, setTip } from "../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import "rc-time-picker/assets/index.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../redux/cartReducer/actions";
import { useCookies } from "react-cookie";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import DrawerRight from "features/eComm/components/DrawerRight";
import axios from "axios";

import PaymentNested from "./components/PaymentNested";
import LogoCenter from "./components/LogoCenter";
import BottomCheckOutNav from "features/eComm/components/BottomCheckOutNav";
import BlankSpace from "features/eComm/components/BlankSpace";
import CustomerSatisfactionQRCode from "features/eComm/components/CustomerSatisfactionQRCode";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import ScrollToDown from "components/scroll/ScrollToDown";
import ScrollToUp from "components/scroll/ScrollToUp";
import Fab from "@mui/material/Fab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FontSizeChanger from "react-font-size-changer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import BarTop from "features/eComm/components/BarTop";
import BarBottom from "features/eComm/components/BarBottom";
import config from "../../config";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const restaurantURL = process.env.REACT_APP_SITE;

const serviceCharge = parseFloat(process.env.REACT_APP_SERVICE_CHARGE);
const tableNumber = sessionStorage.getItem("table");
const enablePickup =
  tableNumber && tableNumber !== "none"
    ? 0
    : parseInt(process.env.REACT_APP_PICKUP);
const enableDineIN =
  tableNumber && tableNumber !== "none"
    ? 1
    : parseInt(process.env.REACT_APP_DINE_IN);
const enalbeDelivery =
  tableNumber && tableNumber !== "none"
    ? 0
    : parseInt(process.env.REACT_APP_DELIVERY);
const usePoints = process.env.REACT_APP_USE_POINTS;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const enableTip = process.env.REACT_APP_ENABLE_TIP;
const pickupDesc = process.env.REACT_APP_PICKUP_DESC;
const dineInDesc = process.env.REACT_APP_DINE_IN_DESC;
const deliveryDesc = process.env.REACT_APP_DELIVERY_DESC;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;
const enableSL = parseInt(process.env.REACT_APP_ENABLE_SL);
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const enableZeroTax = process.env.REACT_APP_ENABLE_ZEROTAX;
const enableQRFooter = process.env.REACT_APP_ENABLE_QR_FOOTER;

//--Time Conditions--//
const startHour = 7;
const startTime = 420;
const endHour = 20;
const endTime = 1220;
const endMinutes = 0;

export const CheckoutSL = (props) => {
  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
    orderTimeSelectValue,
  } = useSelector((state) => state.public);
  const [siteSettings, setSiteSettings] = useState([]);
  const [openTips, setOpenTips] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [tax, setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [pickupTimebyRevenueCenter, setPickupTimebyRevenueCenter] = useState(
    []
  );
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);
  const [showModal, setShowModal] = useState(false);
  const [disableCheckoutMessage, setDisableCheckoutMessage] = useState("");
  const [selectedTime, setSelectedTime] = useState(
    orderTimeSelectValue
      ? {
          label: orderTimeSelectValue,
          value: orderTimeSelectValue,
        }
      : null
  );
  const [updateTime, setUpdateTime] = useState("");
  const [pickupTimeLimits, setPickupTimeLimits] = useState();
  const [availableOrderTypes, setAvailableOrderTypes] = useState();

  const [checkoutContent, setcheckoutContent] = useState("");

  useEffect(() => {
    const checkoutContentText = config.checkoutcontent;

    setcheckoutContent(checkoutContentText);
  }, []);

  //onsreen keyboard
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardVisibleName, setKeyboardVisibleName] = useState(false);
  const [messageToKitchen, setMessageToKitchen] = useState(
    sessionStorage.getItem("instuctions") || ""
  );
  const [name, setName] = useState(sessionStorage.getItem("name") || "");
  const [layoutName, setLayoutName] = useState("default");
  const enternameRef = useRef(null);
  const messageRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(null);

  // Function to handle clicks outside the TextField and Keyboard
  const handleClickOutside = (event) => {
    if (enternameRef.current && !enternameRef.current.contains(event.target)) {
      setKeyboardVisibleName(false);
    }
    if (messageRef.current && !messageRef.current.contains(event.target)) {
      setKeyboardVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update caret position
  const updateCaretPosition = (ref) => {
    const input = ref.current.querySelector("input");
    if (input) {
      setCaretPosition(input.selectionStart);
    }
  };

  // Handle key press for the message input
  const handleOnKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    } else if (button === "{enter}") {
      setKeyboardVisible(false);
    } else if (button === "{bksp}") {
      if (caretPosition > 0) {
        setMessageToKitchen((prev) => {
          const newValue =
            prev.slice(0, caretPosition - 1) + prev.slice(caretPosition);
          setCaretPosition(caretPosition - 1);
          return newValue;
        });
      }
    } else if (button === "{space}") {
      setMessageToKitchen((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + " " + prev.slice(caretPosition); // Add a blank space
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    } else {
      setMessageToKitchen((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + button + prev.slice(caretPosition);
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    }
  };

  // Handle change for the message input
  const handleOnChange = (value) => {
    setMessageToKitchen(value);
    sessionStorage.setItem("instuctions", value);
  };

  // Handle key press for the name input
  const handleOnKeyPressName = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    } else if (button === "{enter}") {
      setKeyboardVisibleName(false);
    } else if (button === "{bksp}") {
      if (caretPosition > 0) {
        setName((prev) => {
          const newValue =
            prev.slice(0, caretPosition - 1) + prev.slice(caretPosition);
          setCaretPosition(caretPosition - 1);
          return newValue;
        });
      }
    } else if (button === "{space}") {
      setName((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + " " + prev.slice(caretPosition); // Add a blank space
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    } else {
      setName((prev) => {
        const newValue =
          prev.slice(0, caretPosition) + button + prev.slice(caretPosition);
        setCaretPosition(caretPosition + 1);
        return newValue;
      });
    }
  };

  // Handle shift key press
  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  // Handle change for the name input
  const handleOnChangeName = (value) => {
    setName(value);
    sessionStorage.setItem("name", value);
  };

  const Swal = require("sweetalert2");

  const [loading1, setLoading] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const {
    cartItems,
    totalPriceItems,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
    totalDiscount,
  } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  // =========== START ==========
  // console.log(props.location, "Loki");
  // =========== END ===========

  // =========== START ==========
  console.log(orderProcessingType, "TYPE CHECKOUT SL");
  // =========== END ===========

  // Get cookie
  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  // Set kitchen message to sessionStorage
  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  const updatePickupTimebyRevenueCenter = (pickup) => {
    if (enableRevenueCenter == 1) {
      setPickupTimebyRevenueCenter(pickup);
    }
  };

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      apiBaseURL +
      "/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "") {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              if (item.Department == dept) {
                totalDiscountPrice += parseFloat(
                  parseFloat(item.DefaultPrice) * item.quantity
                );
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items
              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              } else {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              }
            } else {
              dispatch(setPromoCode(""));
              alert("Coupon is not applicable to the existing Check Item(s).");
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
      })
      .catch((error) => {});
  };

  // Update item quantity
  const updateQuantity = (item, change) => {
    const newCartItems = cartItems
      .map((cartItem) => {
        if (cartItem.ItemID === item.ItemID) {
          const newQuantity = cartItem.quantity + change;
          return { ...cartItem, quantity: newQuantity };
        }
        return cartItem;
      })
      .filter((cartItem) => cartItem.quantity > 0);

    dispatch({
      type: "SET_CART",
      payload: newCartItems,
    });
  };

  // Keep this function just in case it is needed in the future
  const getDiscountRate = (item) => {
    const discountRate =
      (parseFloat(item.ActualPrice) / parseFloat(item.DefaultPrice)) * 100;
    const customerDiscount = parseFloat(
      sessionStorage.getItem("customerDiscount")
    );

    return discountRate;
  };

  const getSubtotalPrice = () => {
    if (totalPriceItems > 0) {
      return parseFloat(totalPriceItems);
    }

    const subtotalPrice = cartItems.reduce((subtotal, item) => {
      const isNumber = typeof item.DefaultPrice === "number";
      const itemPrice = isNumber
        ? item.DefaultPrice / 100
        : parseFloat(item.DefaultPrice) || 0; // Ensure a valid itemPrice
      const quantity = item.quantity || 1; // Default to 1 if quantity is missing
      return subtotal + itemPrice * quantity;
    }, 0);

    return parseFloat(subtotalPrice);
  };

  const getTotalTaxAmount = () => {
    const totalTaxAmount = cartItems.reduce((total, item) => {
      const tax = parseFloat(item.TaxTotal) || 0; // Default to 0 if TaxTotal is missing or invalid
      const quantity = item.quantity || 1; // Default to 1 if quantity is missing or invalid
      return total + tax * quantity;
    }, 0);

    return totalTaxAmount;
  };

  const getTotalDiscount = () => {
    const totalItemDiscount = cartItems.reduce((total, item) => {
      const itemDiscount = parseFloat(item.DiscountTotal) || 0; // Default to 0 if TaxTotal is missing or invalid
      const quantity = item.quantity || 1; // Default to 1 if quantity is missing or invalid
      return total + itemDiscount * quantity;
    }, 0);
    // totalItemDiscount is for each ITEM DISCOUNT
    // totalDiscount (from Redux) is for PROMO/COUPON DISCOUNT
    return totalItemDiscount + totalDiscount;
  };

  const getTotalPrice = () => {
    return (
      getSubtotalPrice() +
      tips +
      getTotalTaxAmount() +
      serviceCharge -
      getTotalDiscount()
    );
  };

  // Set update time
  useEffect(() => {
    var sourceUpdateTime = new EventSource(
      "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" + siteId
    );
    sourceUpdateTime.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (sessionStorage.getItem("update_date_time") !== u_items[0].time) {
          setUpdateTime(u_items[0].time);
          sessionStorage.setItem("update_date_time", u_items[0].time);
        }
      }
    };

    //Set default serviceOption
    // if (enalbeDelivery == 1 && orderProcessingType === 2) {
    //   dispatch(setSelectedServiceOption(2));
    // }
    // if (enablePickup == 1 && orderProcessingType === 1) {
    //   dispatch(setSelectedServiceOption(1));
    // }
    // if (enableDineIN == 1 && orderProcessingType === 0) {
    //   dispatch(setSelectedServiceOption(0));
    // }
  }, []);

  // Check if store is open
  useEffect(() => {
    const url =
      apiBaseURL + "/site/get-site-settings/" + serverId + "/" + siteId;
    axios
      .get(url)
      .then((response) => {
        setSiteSettings(response.data);
        if (response.data.OnOff < 1) {
          Swal.fire({
            title: "Store Message",
            text: response.data.eCommerceDisplayMessage,
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/welcome");
        }
      })
      .catch((error) => {});
  }, [updateTime]);

  // Checkout buffer
  useEffect(() => {
    axios
      .get(`${apiBaseURL}/site/is-checkout-disabled/${serverId}/${siteId}`)
      .then((response) => {
        if (response.data === 1) {
          Swal.fire({
            title: "Store Message",
            text: "Checkout is disabled at this moment.",
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [updateTime]);

  // Check for pinCodeOk in sessionStorage
  useEffect(() => {
    if (
      !sessionStorage.getItem("pinCodeOk") &&
      sessionStorage.getItem("pinCodeOk") !== 1
    ) {
      window.location.href = "/";
    }
  }, []);

  // Set date as current date
  useEffect(() => {
    let date = "";
    if (!orderProcessingDate) {
      date = moment();
    }

    const d = new Date();
    const hour = d.getHours(); //get current hour
    const min = d.getMinutes(); //get current min
    const cdate = d.getDate();
    const cyear = d.getFullYear();
    const cmonth = d.getMonth();

    if (
      d.getDate() == cdate &&
      d.getMonth() == cmonth &&
      d.getFullYear() == cyear &&
      (hour > endHour || (hour == endHour && min >= endMinutes))
    ) {
      //alert("Store is closed. You can order for next day");
      date = moment(d, "MM-DD-YYYY").add(1, "days");
    }

    if (!orderProcessingDate) {
      dispatch(setOrderProcessingDate(date));
    }
  }, []);

  // Set cookie payment
  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  // Set sic_address value from cookie to selectedAddress variable in Redux
  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  }, []);

  // setCookiePayment and removeCookiePayment
  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  // promoCode, discount, discountPercentage
  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        apiBaseURL + "/payment/coupon/" + serverId + "/" + siteId + "/" + tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "") {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                if (item.Department == dept) {
                  totalDiscountPrice += parseFloat(
                    parseFloat(item.DefaultPrice) * item.quantity
                  );
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                } else {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  // Save promo/coupon discount to setTotalDiscount Redux
  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  // Get pickup limit settings
  useEffect(() => {
    const menuName = props.location.state && props.location.state.menuName;

    const currentMenuName = menuName != "" ? menuName : "none";
    const url =
      apiBaseURL +
      "/items/pickup-time-limit/" +
      serverId +
      "/" +
      siteId +
      "/" +
      currentMenuName +
      "/" +
      orderProcessingType;

    axios
      .get(url)
      .then((response) => {
        setPickupTimeLimits(response.data);
      })
      .catch((error) => {});
  }, [orderProcessingType]);

  // Get available order types
  useEffect(() => {
    const revenueCenter =
      props.location.state && props.location.state.revenueCenter
        ? props.location.state.revenueCenter
        : "none";
    const menuName =
      props.location.state && props.location.state.menuName
        ? props.location.state.menuName
        : "none";
    const currentMenuName = menuName != "" ? menuName : "none";

    const url =
      apiBaseURL +
      "/items/available-order-types/" +
      serverId +
      "/" +
      siteId +
      "/" +
      revenueCenter +
      "/" +
      currentMenuName;
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          let orderTypes = [];

          // Map over response.data and push OrderType to orderTypes array
          console.log(response.data, "data");
          response.data.forEach((item) => orderTypes.push(item.OrderType));

          // Create a Set from orderTypes to remove duplicates and convert it back to an array
          const uniqueOrderTypes = Array.from(new Set(orderTypes));
          // Set the state with the array of unique order types
          setAvailableOrderTypes(uniqueOrderTypes);

          console.log(uniqueOrderTypes, orderProcessingType);

          if (uniqueOrderTypes.includes("Dine In")) {
            dispatch(setSelectedServiceOption(0));
          } else if (uniqueOrderTypes.includes("Pick Up")) {
            dispatch(setSelectedServiceOption(1));
          } else {
            dispatch(setSelectedServiceOption(2));
          }
        }
      })
      .catch((error) => {});
  }, []); // run this once, upon component mount

  // Previously retrieved TAX from TAX TABLE (now deprecated but kept for reference)
  // useEffect(() => {
  //   if (parseInt(enableZeroTax) === 1 && enableSL === 1) {
  //     setTax(0);
  //   } else {
  //     const url =
  //       apiBaseURL + "/items/tax/" + siteId + "?server_id=" + serverId;
  //     axios
  //       .get(url)
  //       .then((response) => {
  //         setTax(
  //           parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) /
  //             100
  //         );
  //       })
  //       .catch((error) => {});
  //   }
  // }, []);

  // console.log(
  //   orderProcessingDate,
  //   orderProcessingType,
  //   orderTimeSelectValue,
  //   orderProcessingDate && orderTimeSelectValue
  // );
  return (
    <React.Fragment>
      <div
        className={enableKiosk === "0" ? "HeaderImg" : "HeaderImgeKiosk"}
        style={{
          position: "relative",
          backgroundImage: `url(${siteMedSrc}${
            enableKiosk === "0" ? "welcome-banner.webp" : "background.webp"
          })`,
          color: buttonText,
          height: enableKiosk === "0" ? "250px" : "auto",
          backgroundAttachment: enableKiosk === "0" ? "local" : "scroll",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        />
        <Container style={{ position: "relative", zIndex: 2 }}>
          <Row>
            <Col xs={12}>
              <h2
                style={{
                  color: buttonText,
                }}
                className={
                  enableKiosk === "0"
                    ? "padding-top-20 blankspace center"
                    : "padding-bottom-20 center"
                }
              >
                Your Payment and Billing Details
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${restaurantName}: ${checkoutContent?.title}`}</title>
          <link rel="canonical" href={`${restaurantURL}checkout`} />
          <meta
            name="description"
            content={`${checkoutContent?.description}`}
          />
          <meta name="keywords" content={`${checkoutContent?.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${checkoutContent?.title}`}
          />
          <meta
            property="og:description"
            content={`${checkoutContent?.description}`}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${checkoutContent?.title}`}
          />
          <meta
            property="twitter:description"
            content={`${checkoutContent?.description}`}
          />
        </Helmet>
      </HelmetProvider>
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      {/* {enableKiosk === "1" ? <LogoCenter /> : null} */}
      {enableKiosk === "0" ? <DrawerRight /> : null}
      <Container
        id="target"
        style={{
          position: "relative",
          /*overflowY: "auto",*/
          marginTop: "1rem",
          backgroundColor: "transparent",
        }}
      >
        <Grid style={{ marginLeft: "0.7rem" }}>
          <Button
            style={{
              textTransform: "none",
              color: "gray",
              marginTop: "15px",
              backgroundColor: buttonColor,
              color: buttonText,
              borderColor: buttonColor,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
            }}
            onClick={() =>
              enableRevenueCenter == 1
                ? history.push("/welcome")
                : history.push("/menu")
            }
          >
            Add More
          </Button>

          {cartItems && cartItems.length > 0 && (
            <Button
              style={{
                marginLeft: "1rem",
                textTransform: "none",
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                backgroundColor: buttonColor,
                color: buttonText,
                borderColor: buttonColor,
                marginTop: "15px",
              }}
              onClick={() =>
                Swal.fire({
                  title: "Store Message",
                  text: "This will remove all items from current order, do you wish to proceed?",
                  icon: "info",
                  fontSize: "1.5rem",
                  showCancelButton: true, // This will show the cancel button
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch({
                      type: "SET_CART",
                      payload: [],
                    });
                    history.push(
                      parseInt(enableRevenueCenter) === 1 ? "/welcome" : "/menu"
                    );
                  }
                })
              }
            >
              Clear Cart
            </Button>
          )}
          <Typography
            className="content"
            style={{
              fontWeight: 700,
              paddingTop: "20px",
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
            }}
            gutterBottom
          >
            My Order
          </Typography>
        </Grid>
        <Box height="1rem" />
        <Grid container spacing={1}>
          {cartItems.length === 0 ? (
            <Typography
              style={{
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                textAlign: "center",
                width: "100%",
                color: "#bebebe",
              }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          ) : (
            cartItems.map((item) => {
              const modifiers = item.modifiers?.sort(
                (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
              );

              return (
                <React.Fragment key={item.ItemID}>
                  <TableContainer
                    id="checkoutTable"
                    component={Paper}
                    style={{ backgroundColor: "#EAEAEA" }}
                  >
                    <Table
                      className="tableOrderList"
                      style={{ tableLayout: "fixed" }}
                    >
                      <TableHead>
                        <TableRow className="checkoutMobileBorder">
                          <TableCell width="150px" className="checkcell01">
                            <img
                              // alt={item.FileName}
                              height="100%"
                              width="100%"
                              // title={item.FileName}
                              loading="lazy"
                              src={
                                item.FileName && item.FileName.length > 0
                                  ? siteImgSrc + item.FileName
                                  : null
                              }
                              style={{ borderRadius: "10px" }}
                            />
                          </TableCell>

                          <TableCell
                            id="checkoutItemName"
                            className="checkcell02"
                            colSpan={6}
                            style={{ width: "30%" }}
                          >
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                fontWeight: 700,
                                fontSize:
                                  enableKiosk === "0" ? "1.25rem" : "1.5rem",
                              }}
                              gutterBottom
                            >
                              {item.ItemName}
                            </Typography>
                            {item.modifiers && item.modifiers.length > 0 ? (
                              <Typography
                                style={{
                                  fontWeight: 700,
                                  marginTop: "1rem",
                                  fontSize:
                                    enableKiosk === "0" ? "1.25rem" : "1.5rem",
                                }}
                              >
                                Add-On:
                              </Typography>
                            ) : null}
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      component="span"
                                      style={{
                                        fontWeight: 500,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      {mod.ItemDescription} - ${" "}
                                      {numberWithCommas(mod.DefaultPrice / 100)}
                                      <br></br>
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>

                          <TableCell align="center" style={{ width: "40%" }}>
                            <Grid
                              container
                              justifyContent="start"
                              className="tableRemoveAdd"
                            >
                              <Box
                                borderRadius="4px"
                                display="flex"
                                alignItems="center"
                                padding={
                                  enableKiosk === "0" ? ".25rem" : ".5rem"
                                }
                                style={{
                                  backgroundColor: buttonColor,
                                  color: buttonText,
                                  borderColor: buttonColor,
                                  border: "none",
                                }}
                              >
                                {item.comments != "Delivery Charge" &&
                                item.comments != "ItemTagMenu" ? (
                                  <>
                                    <Button
                                      style={{
                                        minWidth: "40px",
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                    >
                                      <RemoveIcon
                                        style={{
                                          backgroundColor: buttonColor,
                                          color: buttonText,
                                          borderColor: buttonColor,
                                          border: "none",
                                        }}
                                        fontSize={
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem"
                                        }
                                        onClick={() => updateQuantity(item, -1)}
                                      />
                                    </Button>
                                    <Box
                                      style={{
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                      fontSize={
                                        enableKiosk === "0"
                                          ? "1.25rem"
                                          : "1.5rem"
                                      }
                                      margin={
                                        enableKiosk === "0" ? "0" : "0 1rem"
                                      }
                                    >
                                      {item.quantity}
                                    </Box>
                                    <Button
                                      style={{
                                        minWidth: "40px",
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                    >
                                      <AddIcon
                                        style={{
                                          backgroundColor: buttonColor,
                                          color: buttonText,
                                          borderColor: buttonColor,
                                          border: "none",
                                        }}
                                        fontSize={
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem"
                                        }
                                        onClick={() => updateQuantity(item, 1)}
                                      />
                                    </Button>
                                  </>
                                ) : null}
                              </Box>
                            </Grid>
                          </TableCell>

                          <TableCell align="right" style={{ width: "30%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                              }}
                            >
                              <Box
                                className="mb-2 flex items-center"
                                sx={{ justifyContent: "flex-end" }}
                              >
                                <Box className="flexRowCompact">
                                  <Box className="primaryItemDisplay">
                                    <Box
                                      className="flexTruncateBaseline"
                                      sx={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      <span>$</span>
                                      <span>
                                        {numberWithCommas(
                                          (parseFloat(item.DefaultPrice) /
                                            (typeof item.DefaultPrice ===
                                            "number"
                                              ? 100
                                              : 1)) *
                                            item.quantity
                                        ) + (usePoints === 1 ? " pts" : "")}
                                      </span>
                                    </Box>
                                  </Box>

                                  {/* {parseFloat(item.DiscountTotal) > 0 && (
                                    <Box className="mb-15">
                                      <Box className="discountTag white">
                                        <span aria-label={`-${discountRate}%`}>
                                          -{discountRate}%
                                        </span>
                                      </Box>

                                      <Box className="originalPriceTag">
                                        <Box className="flexTruncateBaseline">
                                          <span>$</span>
                                          <span className="truncate">
                                            {numberWithCommas(
                                              parseFloat(item.DefaultPrice) *
                                                item.quantity
                                            )}
                                          </span>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )} */}
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow style={{ borderBottom: "1px solid #ebebeb" }}>
                          <TableCell></TableCell>

                          <TableCell className="checkcell02">
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      {mod.ItemDescription}
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="checkcell03"
                          ></TableCell>
                          <TableCell align="center" className="checkcell04">
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.5rem",
                                      }}
                                    >
                                      ${" "}
                                      {numberWithCommas(mod.DefaultPrice / 100)}
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </React.Fragment>
              );
            })
          )}
        </Grid>
        <Box height="1rem" />

        <TableContainer
          id="checkoutTotal"
          component={Paper}
          className="checkoutMobileFlexTotal"
        >
          {/* <Table className="checkoutMobileFlexTotalInner"> */}
          <Table>
            <TableBody>
              {/* SUBTOTAL */}
              <TableRow>
                <TableCell colSpan={3} style={{ width: "17%" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Subtotal
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      // parseFloat(totalPriceItems - itemsTotalDiscount)
                      getSubtotalPrice()
                    )}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* TIP */}
              {enableTip == 1 ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                    >
                      Tip
                      <Button
                        style={{
                          textTransform: "none",
                          marginLeft: "10px",
                          color: "gray",
                          fontSize: "0.8rem",
                          backgroundColor: buttonColor,
                          borderColor: buttonColor,
                          border: "1px solid",
                          borderRadius: "6px",
                          padding: "5px",
                          alignItems: "center",
                          color: buttonText,
                        }}
                        onClick={() => setOpenTips(true)}
                      >
                        Change
                      </Button>
                    </Typography>
                  </TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="right" style={{ width: "30%" }}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                    >
                      ${numberWithCommas(tips)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}

              {/* TAX */}
              <TableRow>
                <TableCell colSpan={3} style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Tax
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      // Math.round(
                      //   (totalPriceItems - itemsTotalDiscount) * tax * 100 +
                      //     1e-10
                      // ) / 100
                      getTotalTaxAmount()
                    )}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* SERVICE FEE */}
              <TableRow></TableRow>

              {/* DISCOUNTS */}
              <TableRow>
                <TableCell colSpan={3} style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Discounts
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    ${numberWithCommas(getTotalDiscount())}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* TOTAL AMOUNT */}
              <TableRow
                className="amountDueMobile"
                style={{ marginTop: "10px", paddingTop: "10px" }}
              >
                <TableCell colSpan={5} style={{ width: "auto" }}>
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    Amount Due
                  </Typography>
                </TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      // parseFloat(totalPriceItems - itemsTotalDiscount) +
                      //   parseFloat(
                      //     Math.round(
                      //       (totalPriceItems - itemsTotalDiscount) * tax * 100 +
                      //         1e-10
                      //     ) / 100
                      //   )
                      getTotalPrice()
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <BlankSpace />
        <br />
        {/******* Start Message to Kitchen *******/}
        {parseInt(enableKiosk) === 1 ? (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              * Enter your name
            </Typography>
            <Grid item xs={12}>
              <div ref={enternameRef}>
                <TextField
                  className="eKioskInput"
                  style={{ width: "100%" }}
                  onChange={(e) => handleOnChangeName(e.target.value)}
                  onFocus={() => setKeyboardVisibleName(true)}
                  variant="outlined"
                  name="name"
                  value={name}
                  onClick={() => updateCaretPosition(enternameRef)} // Update caret position on click
                  placeholder=""
                  autoComplete="off"
                  InputLabelProps={{
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                  InputProps={{
                    "aria-label": "name",
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                />

                {keyboardVisibleName && (
                  <Keyboard
                    layoutName={layoutName}
                    onKeyPress={handleOnKeyPressName}
                    // onChange={handleOnChangeName}
                    inputName="name"
                  />
                )}
              </div>
            </Grid>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <div ref={messageRef}>
                <TextField
                  className="eKioskInput"
                  style={{ width: "100%" }}
                  onChange={(e) => handleOnChange(e.target.value)}
                  onFocus={() => setKeyboardVisible(true)}
                  variant="outlined"
                  name="message"
                  value={messageToKitchen}
                  onClick={() => updateCaretPosition(messageRef)} // Update caret position on click
                  placeholder=""
                  autoComplete="off"
                  InputLabelProps={{
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                  InputProps={{
                    "aria-label": "message",
                    style: { fontSize: "1.25rem" }, // Adjust based on enableKiosk
                  }}
                />

                {keyboardVisible && (
                  <Keyboard
                    layoutName={layoutName}
                    onKeyPress={handleOnKeyPress}
                    // onChange={handleOnChange}
                    inputName="message"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
                paddingLeft: "1rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <Form provider={methods} onSubmit={handleSubmit}>
                <Input
                  className={enableKiosk === "1" ? "eKioskInput" : null}
                  onChange={setKitchenMsg}
                  variant="outlined"
                  name="message"
                  placeholder="Enter special instructions"
                  autoComplete="off"
                  border="1px solid #bebebe"
                />
              </Form>
            </Grid>
          </Grid>
        )}
        {/*******End Message to Kitchen *******/}
      </Container>
      <TipsDrawerSL open={openTips} setOpen={setOpenTips} />
      <SelectCreditCardDrawerSL
        open={openUserCards}
        setOpen={setOpenUserCards}
      />
      {parseInt(enableKiosk) === 0 && (
        <ServiceOptionsDrawerSL
          open={openServiceOptions}
          menuName={props.location.state && props.location.state.menuName}
          pickupTimeLimits={pickupTimeLimits}
          availableOrderTypes={availableOrderTypes}
          siteSettings={siteSettings}
          setOpen={setOpenServiceOptions}
          updatePickupTimebyRevenueCenter={updatePickupTimebyRevenueCenter.bind(
            this
          )}
          setSelectedTime={setSelectedTime}
          revenueCenter={
            props.location.state && props.location.state.revenueCenter
          }
        />
      )}
      <Container
        id="target"
        style={{
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        <PaymentSelectorSL
          pickupTimebyRevenueCenter={pickupTimebyRevenueCenter}
          selectedTime={selectedTime}
          setOpenUserCards={setOpenUserCards}
          getSubtotalPrice={getSubtotalPrice}
          getTotalTaxAmount={getTotalTaxAmount}
          getTotalDiscount={getTotalDiscount}
          getTotalPrice={getTotalPrice}
        />
        <BlankSpace />
      </Container>
      {enableKiosk === "1" ? (
        <BottomCheckOutNav
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        />
      ) : null}
      {/* <ChangeTextSize /> */}
      {enableKiosk === "0" ? <ScrollToDown /> : null}
      {enableKiosk === "0" ? <ScrollToUp /> : null}
      {enableNewUI === "1" ? (
        <>
          <BarTop />
          <BarBottom />
        </>
      ) : null}
    </React.Fragment>
  );
};

export const ServiceOptionsDrawerSL = ({
  open,
  menuName,
  pickupTimeLimits,
  availableOrderTypes,
  siteSettings,
  setOpen,
  updatePickupTimebyRevenueCenter,
  setSelectedTime,
  revenueCenter,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(false);
  const [timeConstant, setTimeConstant] = useState([]);
  const [pickupCounter, setPickupCounter] = useState([]);
  const [pickupTimebyRevenueCenter, setPickupTimebyRevenueCenter] = useState(
    []
  );

  //const [time, setTime] = useState(moment().add("20", "mins"));
  const { orderProcessingDate, orderProcessingType, orderTimeSelectValue } =
    useSelector((state) => state.public);
  const history = useHistory();

  const { cartItems } = useSelector((state) => state.cart);

  // group cart items by revenueCenter
  const revenueCenters = cartItems.reduce((rv, item) => {
    const center = item.RevenueCenter || item.revenueCenter;

    // Initialize the array if it doesn't exist, then push the item
    if (!rv[center]) {
      rv[center] = [];
    }

    rv[center].push(item);
    return rv;
  }, {});

  const menuNames = cartItems.reduce(function (rv, x) {
    (rv[x["menuName"]] = rv[x["menuName"]] || []).push(x);
    return rv;
  }, {});
  const uniqueMenuNames = [...new Set(Object.keys(menuNames))];

  const updateTime = (e) => {
    // dispatch(setOrderProcessingDate(moment()))
    let date;
    if (Date.parse(orderProcessingDate) > 0) {
      const timeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + e.value
      );
      //console.log(timeAndDate, "TIME AND DATE 1234");
      date = timeAndDate;
      dispatch(setOrderProcessingDate(timeAndDate));
    } else {
      //alert("Invalid Date");
      const today = new Date();
      const defautTimeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + today
      );
      date = defautTimeAndDate;
    }

    // If these fields doesn't have value update the order processing date
    if (!orderProcessingDate && !orderProcessingType && !orderTimeSelectValue) {
      dispatch(setOrderProcessingDate(date));
    }

    //For revenue center enabled
    if (enableRevenueCenter == 1) {
      const revPickup = { revenueCenter: e.rev, pickup: e.value };

      if (pickupTimebyRevenueCenter && pickupTimebyRevenueCenter.length > 0) {
        setPickupTimebyRevenueCenter(
          pickupTimebyRevenueCenter.filter((i) => i.revenueCenter != e.rev)
        );
      }

      setPickupTimebyRevenueCenter((pickupTimebyRevenueCenter) => [
        ...pickupTimebyRevenueCenter,
        revPickup,
      ]);
    }
  };

  const addDeliveryCharge = async () => {
    try {
      const url = `${apiBaseURL}/items/item-charge-delivery/${serverId}/${siteId}`;
      const response = await axios.get(url);

      if (response.data && response.data.length > 0) {
        const itemChargeDelivery = response.data[0];

        if (cartItems.length > 0) {
          const cleanItemChargeDelivery = cartItems.filter(
            (item) => item.comments !== "Delivery Charge"
          );

          const deliveryChargeItem = {
            ...itemChargeDelivery,
            quantity: 1,
            modifiers: [],
            comments: "Delivery Charge",
          };

          dispatch({
            type: "SET_CART",
            payload: [...cleanItemChargeDelivery, deliveryChargeItem],
          });
        }
      }
    } catch (error) {
      console.error("Error adding delivery charge:", error);
    }
  };

  const removeDeliveryCharge = () => {
    const cartItemsRemoveCharge = cartItems.filter(
      (i) => i.comments !== "Delivery Charge"
    );

    dispatch({
      type: "SET_CART",
      payload: cartItemsRemoveCharge,
    });
  };

  useEffect(() => {
    if (orderProcessingType != 2) {
      removeDeliveryCharge();
    }
  }, []);

  useEffect(() => {
    updatePickupTimebyRevenueCenter(pickupTimebyRevenueCenter);
  }, [pickupTimebyRevenueCenter]);

  //get pickup limit for today
  useEffect(() => {
    const baseUrl =
      enableRevenueCenter == 1
        ? `${apiBaseURL}/items/pickup-orders-today-revenue-center/`
        : `${apiBaseURL}/items/pickup-orders-today/`;

    const url = baseUrl + serverId + "/" + siteId;
    axios
      .get(url)
      .then((response) => {
        setPickupCounter(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (timeConstant && timeConstant.length > 0) {
      timeConstant &&
        timeConstant.map((time, i) => {
          if (i === 0) {
            updateTime({
              label: time.name,
              value: time.code,
            });
          }
        });
    }
  }, [timeConstant, orderProcessingType]);

  useEffect(() => {
    var tempTimeConstant = [];
    var d = new Date();
    var selectedDate =
      Date.parse(orderProcessingDate) > 0 ? new Date(orderProcessingDate) : d;
    var hour = d.getHours(); //get current hour
    var min = d.getMinutes(); //get current min
    var cdate = d.getDate();
    var cyear = d.getFullYear();
    var cmonth = d.getMonth();

    var interval = 15; //minutes interval
    var times = []; // time array
    var tt = startTime; // start time
    var ap = ["AM", "PM"]; // AM-PM

    //--round time--//
    var date_r = new Date(d.getTime());

    // date_r.setMinutes(((((date_r.getMinutes() + 7.5) / 15) | 0) * 15) % 60);
    date_r.setMinutes(date_r.getMinutes() + interval);
    //date_r.setHours(date_r.setMinutes(date_r.getMinutes() + 8));
    //--end round time--//

    if (selectedDate) {
      //loop to increment the time and push results in array
      for (var i = 0; tt <= 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format

        const hh_am_pm = hh > 11 ? hh : ((hh + startHour) % 12) + 1;
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        let final_hh = ("0" + (hh % 12)).slice(-2);
        final_hh = final_hh == 0 ? 12 : final_hh;
        times[i] =
          final_hh +
          ":" +
          ("0" + mm).slice(-2) +
          " " +
          ap[Math.floor(hh_am_pm / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]

        tt = tt + interval;

        if (tt <= endTime) {
          //console.log(hh + "=" + hour);
          //console.log(mm + "=" + (min + 15));
          if (
            selectedDate.getDate() == cdate &&
            selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear
          ) {
            //--set time to current option--//
            let timeOption = new Date(d.getTime());
            timeOption.setHours(hh);
            timeOption.setMinutes(mm);

            if (
              //(hh >= hour && (mm >= min + 15 || mm <= min - 30)) ||
              (hh >= hour && timeOption.getTime() > date_r.getTime()) ||
              hour < startHour
            ) {
              tempTimeConstant.push({ code: times[i], name: times[i] });
              // console.log("push:" + times[i]);
            }
          } else {
            tempTimeConstant.push({ code: times[i], name: times[i] });
          }
        }
      }

      setTimeConstant(tempTimeConstant);

      //---Start Override Pickup Time Limits---//
      if (
        enableRevenueCenter == 1 &&
        pickupTimeLimits &&
        menuName &&
        revenueCenters
      ) {
        const tempTimeConstant = [];
        const serviceOption =
          orderProcessingType == 0
            ? "Dine In"
            : orderProcessingType == 1
              ? "Pick Up"
              : "Delivery";

        // improved forEach loop to add pickup time
        pickupTimeLimits.forEach(
          ({ MenuName, OrderType, PickUpTime, RevenueCenter }) => {
            if (
              MenuName?.includes(menuName) &&
              OrderType === serviceOption &&
              Object.keys(revenueCenters).some((key) =>
                key.includes(RevenueCenter)
              )
            ) {
              tempTimeConstant.push({
                code: PickUpTime,
                name: PickUpTime,
              });
            }
          }
        );

        const sortedTempTimeConstant = tempTimeConstant.sort((a, b) => {
          return (
            new Date("1970/01/01 " + a.code) - new Date("1970/01/01 " + b.code)
          );
        });

        setTimeConstant(sortedTempTimeConstant);
        // console.log(tempTimeConstant, "tempTimeConstant");
      } else {
        if (pickupTimeLimits) {
          const tempTimeConstant = [];
          const serviceOption =
            orderProcessingType == 0
              ? "Dine In"
              : orderProcessingType == 1
                ? "Pick Up"
                : "Delivery";

          pickupTimeLimits.forEach((p) => {
            if (p.OrderType === serviceOption) {
              tempTimeConstant.push({
                code: p.PickUpTime,
                name: p.PickUpTime,
              });
            }
          });

          const sortedTempTimeConstant = tempTimeConstant.sort((a, b) => {
            return (
              new Date("1970/01/01 " + a.code) -
              new Date("1970/01/01 " + b.code)
            );
          });

          setTimeConstant(sortedTempTimeConstant);
        }
      }
      //---End Override Pickup Time Limits---//

      if (
        enableKiosk === 0 &&
        selectedDate.getDate() == cdate &&
        selectedDate.getMonth() == cmonth &&
        selectedDate.getFullYear() == cyear &&
        (hour > endHour || (hour == endHour && min >= endMinutes))
      ) {
        // if (orderProcessingType == 1 || orderProcessingType == 2) {
        //   alert("Store is closed. Pickup & Delivery is for current date only.");
        //   removeDeliveryCharge();
        //   if (enableRevenueCenter == 1) {
        //     history.push("/welcome");
        //   } else {
        //     history.push("/menu");
        //   }
        // } else {
        alert(
          "Store is closed. You can order on the next day when store is open."
        );
        if (enableRevenueCenter == 1) {
          history.push("/welcome");
        } else {
          history.push("/menu");
        }
        // dispatch(
        //   setOrderProcessingDate(
        //     moment(selectedDate, "MM-DD-YYYY").add(1, "days")
        //   )
        // );
        //setDate(selectedDate);
        //}
      }
      if (orderProcessingDate) {
        if (orderProcessingDate && orderTimeSelectValue) return; // If these state has value don't update it
        if (
          selectedDate.getDate() > cdate ||
          (selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear) ||
          selectedDate.getMonth() > cmonth ||
          selectedDate.getFullYear() > cyear
        ) {
          if (orderProcessingType == 0 && siteSettings.FutureDateOnOff == 0) {
            // alert("Dine In is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
          if (orderProcessingType == 1 && siteSettings.FutureDateOnOff == 0) {
            // alert("Pickup is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
          if (orderProcessingType == 2 && siteSettings.FutureDateOnOff == 0) {
            // alert("Delivery is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
        }
      }
    }
  }, [date, siteSettings, pickupTimeLimits, menuName, orderProcessingType]);

  useEffect(() => {
    if (!orderProcessingDate && timeConstant && timeConstant.length < 1) {
      dispatch(setOrderProcessingDate(""));
    }
  }, [orderProcessingDate, orderProcessingType, timeConstant]);

  // let timeConstant = [
  //   { code: "", name: "Select Pickup Time" },
  //   { code: "11:00 am", name: "11:00 am" },
  //   { code: "11:15 am", name: "11:15 am" },
  //   { code: "11:30 am", name: "11:30 am" },
  //   { code: "11:30 am", name: "11:45 am" },
  //   { code: "12:00 pm", name: "12:00 pm" },
  //   { code: "12:15 pm", name: "12:15 pm" },
  //   { code: "12:30 pm", name: "12:30 pm" },
  //   { code: "01:00 pm", name: "01:00 pm" },
  //   { code: "01:15 pm", name: "01:15 pm" },
  //   { code: "01:30 pm", name: "01:30 pm" },
  //   { code: "02:00 pm", name: "02:00 pm" },
  //   { code: "02:15 pm", name: "02:15 pm" },
  //   { code: "02:30 pm", name: "02:30 pm" },
  //   { code: "03:00 pm", name: "03:00 pm" },
  //   { code: "03:15 pm", name: "03:15 pm" },
  //   { code: "03:30 pm", name: "03:30 pm" },
  //   { code: "04:00 pm", name: "04:00 pm" },
  //   { code: "04:15 pm", name: "04:15 pm" },
  //   { code: "04:30 pm", name: "04:30 pm" },
  //   { code: "05:00 pm", name: "05:00 pm" },
  //   { code: "05:30 pm", name: "05:30 pm" },
  //   { code: "06:00 pm", name: "06:00 pm" },
  //   { code: "06:30 pm", name: "06:30 pm" },
  //   { code: "07:00 pm", name: "07:00 pm" },
  //   { code: "07:30 pm", name: "07:30 pm" },
  //   { code: "08:00 pm", name: "08:00 pm" },
  //   { code: "08:30 pm", name: "08:30 pm" },
  //   { code: "09:00 pm", name: "09:00 pm" },
  // ];

  // console.log(orderProcessingDate, "first load");

  useEffect(() => {
    uniqueMenuNames.map((menu) => {
      const url =
        apiBaseURL +
        "/items/item-tag-menu/" +
        serverId +
        "/" +
        siteId +
        "/" +
        menu;
      axios
        .get(url)
        .then((response) => {
          const itemTagMenu = response.data[0];
          if (cartItems.length !== 0 && itemTagMenu) {
            const cleanItemTagMenuCartItems = cartItems.filter(
              (i) => i.comments !== "ItemTagMenu"
            );

            dispatch({
              type: "SET_CART",
              payload: cleanItemTagMenuCartItems,
            });

            dispatch({
              type: "SET_CART",
              payload: [
                ...cleanItemTagMenuCartItems,
                {
                  ...itemTagMenu,
                  quantity: 1,
                  modifiers: [],
                  comments: "ItemTagMenu",
                },
              ],
            });
          }
        })
        .catch((error) => {});
    });
  }, []);

  return (
    <Container id="target">
      <Grid open={open} onClose={() => setOpen(false)} anchor="bottom">
        <Box paddingTop="2rem" paddingBottom="2rem">
          <Typography
            className="content"
            style={{
              fontWeight: 700,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
            }}
            gutterBottom
          >
            Select Service Option
          </Typography>
          <Typography gutterBottom>
            {`${
              orderProcessingType == 0
                ? "Dine In"
                : orderProcessingType == 1
                  ? "Pickup"
                  : "Delivery"
            } -`}{" "}
            {tableNumber && tableNumber === "none"
              ? moment(orderProcessingDate).format("MMMM DD, YYYY h:mm a") ==
                "Invalid date"
                ? "No time slots for " +
                  menuName +
                  " available. Please remove the items from your cart and choose a different menu."
                : moment(orderProcessingDate).format("MMMM DD, YYYY h:mm a")
              : moment(orderProcessingDate).format("MMMM DD, YYYY")}{" "}
          </Typography>
          <Box height="1rem" />
          <Grid container spacing={2}>
            {enableDineIN == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Dine In") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  alignItems="center"
                  borderRadius="10px"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 0 && "1px solid #bebebe"}
                  color={orderProcessingType == 0 && buttonText}
                  bgcolor={orderProcessingType == 0 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(0));
                    removeDeliveryCharge();
                  }}
                >
                  <Typography className="content">Dine In</Typography>
                </Box>
              </Grid>
            ) : null}
            {enablePickup == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Pick Up") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  alignItems="center"
                  borderRadius="10px"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 1 && "1px solid #bebebe"}
                  color={orderProcessingType == 1 && buttonText}
                  bgcolor={orderProcessingType == 1 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(1));
                    // const d = new Date();
                    // dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
                    // setDate(moment(d, "MM-DD-YYYY"));
                    removeDeliveryCharge();
                  }}
                >
                  <Typography className="content">Pickup</Typography>
                </Box>
              </Grid>
            ) : null}
            {enalbeDelivery == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Delivery") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  borderRadius="10px"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 2 && "1px solid #bebebe"}
                  color={orderProcessingType == 2 && buttonText}
                  bgcolor={orderProcessingType == 2 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(2));
                    // const d = new Date();
                    // dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
                    // setDate(moment(d, "MM-DD-YYYY"));
                    addDeliveryCharge();
                  }}
                >
                  <Typography className="content">Room Delivery</Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Box height="1rem" />
          <Grid>
            {timeConstant && timeConstant.length > 0 ? (
              <Box>
                <Typography
                  className="content"
                  style={{
                    fontWeight: 600,
                    fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                  }}
                >
                  Select date
                </Typography>
                <SingleDatePicker
                  className="content"
                  focused={focused}
                  numberOfMonths={1}
                  date={orderProcessingDate}
                  id="date_input"
                  onDateChange={(date) => {
                    if (
                      date === null ||
                      !date.isSame(orderProcessingDate, "day")
                    ) {
                      console.log("Selected date is not the current date.");
                      dispatch(setOrderProcessingDate(orderProcessingDate));
                      setFocused(false);
                      setDate(orderProcessingDate);
                    } else {
                      console.log("Selected date is the current date.");
                      dispatch(setOrderProcessingDate(date));
                      setFocused(false);
                      setDate(date);
                    }
                  }}
                  placeholder="Select date"
                  orientation="horizontal"
                  onFocusChange={(data) => {
                    setFocused(data.focused);
                  }}
                  isDayBlocked={(day) => {
                    const d = new Date(day);
                    return d.getDay() === 0;
                  }}
                />
              </Box>
            ) : null}
          </Grid>
          <Box height="1rem" />

          <Grid container hidden={tableNumber && tableNumber !== "none"}>
            {timeConstant && timeConstant.length > 0 ? (
              <Box width="100%">
                <Typography className="content" style={{ fontWeight: 600 }}>
                  Select time
                </Typography>
                {enableRevenueCenter == 1 &&
                revenueCenters &&
                orderProcessingType >= 0 &&
                orderProcessingType <= 2 ? (
                  <>
                    {Object.keys(revenueCenters).find((item) =>
                      item.includes(revenueCenter)
                    ) ? (
                      <div key={revenueCenter}>
                        <Typography className="content">
                          {orderProcessingType == 0
                            ? "Dine In at " + revenueCenter
                            : orderProcessingType == 1
                              ? "Pickup at " + revenueCenter
                              : "Delivery from " + revenueCenter}
                        </Typography>
                        <Select
                          isSearchable={false}
                          key={JSON.stringify(timeConstant)}
                          label={revenueCenter}
                          className="content"
                          options={
                            timeConstant &&
                            timeConstant.map((time) => {
                              const revCounter = pickupCounter.filter(
                                (p) => Object.keys(p) == revenueCenter
                              )[0];

                              const orderCount =
                                revCounter &&
                                revCounter[revenueCenter][time.code];

                              if (pickupTimeLimits) {
                                const isFoundRevenueCenter =
                                  pickupTimeLimits.some(
                                    (p) => p.RevenueCenter == revenueCenter
                                  );

                                let orderLimit;
                                if (menuName == "none" || menuName == "") {
                                  orderLimit = pickupTimeLimits.filter(
                                    (p) =>
                                      p.RevenueCenter == revenueCenter &&
                                      String(p.PickUpTime)
                                        .toUpperCase()
                                        .localeCompare(
                                          String(time.code).toUpperCase()
                                        ) == 0
                                  )[0];
                                } else {
                                  orderLimit = pickupTimeLimits.filter(
                                    (pickupTime) => {
                                      const {
                                        MenuName,
                                        RevenueCenter,
                                        PickUpTime,
                                      } = pickupTime;

                                      return (
                                        MenuName &&
                                        MenuName.includes(menuName) &&
                                        RevenueCenter === revenueCenter &&
                                        String(PickUpTime).toUpperCase() ===
                                          String(time.code).toUpperCase()
                                      );
                                    }
                                  )[0];
                                }

                                if (
                                  orderLimit &&
                                  String(orderLimit.PickUpTime)
                                    .toUpperCase()
                                    .localeCompare(
                                      String(time.name).toUpperCase()
                                    ) == 0
                                ) {
                                  if (
                                    orderCount &&
                                    orderCount >= orderLimit.AvailableOrders
                                  ) {
                                    return {
                                      label:
                                        time.name +
                                        " Full - No Available Order Slots",
                                      value: "",
                                      isDisabled: true,
                                      rev: "",
                                    };
                                  } else {
                                    return {
                                      label: time.name,
                                      value: time.code,
                                      rev: revenueCenter,
                                    };
                                  }
                                } else {
                                  if (pickupTimeLimits.length < 1) {
                                    return {
                                      label: time.name,
                                      value: time.code,
                                      rev: revenueCenter,
                                    };
                                  } else {
                                    if (isFoundRevenueCenter) {
                                      return {
                                        label: time.name,
                                        value: time.code,
                                        rev: "hidden",
                                        isDisabled: true,
                                      };
                                    } else {
                                      return {
                                        label: time.name,
                                        value: time.code,
                                        rev: revenueCenter,
                                      };
                                    }
                                  }
                                }
                              } else {
                                return {
                                  label: time.name,
                                  value: time.code,
                                  rev: revenueCenter,
                                };
                              }
                            })
                          }
                          filterOption={(option) => {
                            if (option.data.rev === "hidden") {
                              return false;
                            } else {
                              return option;
                            }
                          }}
                          defaultValue={
                            orderTimeSelectValue && {
                              label: orderTimeSelectValue,
                              value: orderTimeSelectValue,
                            }
                            //timeConstant.map((time, i) => {
                            //   if (i === 0) {
                            //     const isFullLimit = pickupCounter.filter(
                            //       (p) =>
                            //         Object.keys(p) == item &&
                            //         Object.keys(p).find(
                            //           (i) =>
                            //             String(Object.keys(p[i])[0])
                            //               .toUpperCase()
                            //               .localeCompare(
                            //                 String(time.code).toUpperCase()
                            //               ) === 0 &&
                            //             pickupTimeLimits.find(
                            //               (ptl) =>
                            //                 ptl.RevenueCenter == item &&
                            //                 String(ptl.PickUpTime)
                            //                   .toUpperCase()
                            //                   .localeCompare(
                            //                     String(time.code).toUpperCase()
                            //                   ) === 0
                            //             ) &&
                            //             pickupTimeLimits.find(
                            //               (ptl2) =>
                            //                 ptl2.RevenueCenter == item &&
                            //                 parseInt(p[i][time.code]) ===
                            //                   parseInt(ptl2.AvailableOrders)
                            //             )
                            //         )
                            //     );
                            //     let notAvailableTime;
                            //     if (isFullLimit[0]) {
                            //       notAvailableTime = Object.keys(
                            //         isFullLimit[0][item]
                            //       )[0];
                            //     }

                            //     if (
                            //       isFullLimit[0] &&
                            //       String(notAvailableTime)
                            //         .toUpperCase()
                            //         .localeCompare(
                            //           String(time.code).toUpperCase()
                            //         ) === 0
                            //     ) {
                            //       return {
                            //         label:
                            //           time.name +
                            //           " Full - No Available Order Slots",
                            //         value: "",
                            //         isDisabled: true,
                            //         rev: "",
                            //       };
                            //     } else {
                            //       return {
                            //         label: time.name,
                            //         value: time.code,
                            //         rev: item,
                            //       };
                            //     }
                            //   }
                            // })
                          }
                          styles={{
                            menu: (provided, state) => ({
                              ...provided,
                              fontSize: "large",
                            }),

                            singleValue: (provided, state) => ({
                              ...provided,
                              fontSize: "large",
                            }),
                          }}
                          onChange={(e) => {
                            setSelectedTime(e);
                            updateTime(e);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Select
                    isSearchable={false}
                    className="content"
                    key={JSON.stringify(timeConstant)}
                    options={
                      timeConstant &&
                      timeConstant.map((time) => {
                        return {
                          label: time.name,
                          value: time.code,
                        };
                      })
                    }
                    defaultValue={
                      timeConstant &&
                      timeConstant.map((time, i) => {
                        if (i === 0) {
                          return {
                            label: time.name,
                            value: time.code,
                          };
                        }
                      })
                    }
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: "large",
                      }),

                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: "large",
                      }),
                    }}
                    onChange={updateTime}
                  />
                )}
              </Box>
            ) : null}
          </Grid>
          <BlankSpace />
          <div></div>
        </Box>
      </Grid>
    </Container>
  );
};

export const TipsDrawerSL = ({ open, setOpen }) => {
  const { totalPriceItems } = useSelector((state) => state.cart);
  const tipsConstant = [0.1, 0.15, 0.2, 0.25];
  const dispatch = useDispatch();
  const initialValues = {
    tip: 0,
  };

  const validationSchema = Yup.object({
    tip: Yup.mixed(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = methods;

  const handleSubmit = (d) => {
    // console.log(d);
    dispatch(setTip(d.tip));
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"bottom"}>
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      <Box id="target" padding="1rem" height="70vh" position="relative">
        <Typography className="content" style={{ fontWeight: 700 }}>
          Tips
        </Typography>
        <Toolbar />
        <Grid container spacing={2}>
          {tipsConstant.map((tip) => {
            return (
              <Grid key={tip} item xs={3}>
                <Button>
                  <Box
                    display="flex"
                    //border="1px solid black"
                    //borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    padding="0rem"
                    width="60px"
                    height="60px"
                    onClick={() =>
                      setValue("tip", (tip * totalPriceItems).toFixed(2))
                    }
                  >
                    <Typography className="content">{tip * 100} %</Typography>
                  </Box>
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Toolbar />
        <Form provider={methods} onSubmit={handleSubmit}>
          <Input label="Amount $" name="tip" />
          <Box
            position="absolute"
            bottom="auto"
            left="0px"
            padding="1rem"
            width="100%"
          >
            <Button className="content" color="primary" fullWidth type="submit">
              Apply Tip
            </Button>
          </Box>
        </Form>
      </Box>
    </Drawer>
  );
};

const PaymentSelectorSL = ({
  pickupTimebyRevenueCenter,
  selectedTime,
  setOpenUserCards,
  getSubtotalPrice,
  getTotalTaxAmount,
  getTotalDiscount,
  getTotalPrice,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));
  const classes = useStyles();

  //---From world pay component converted for SL meal balance payment---//
  const {
    totalPriceItems,
    tips,
    cartItems,
    promoCode,
    totalDiscount,
    rewardsBalance,
    giftCardBalance,
    orderSession,
  } = useSelector((state) => state.cart);
  const {
    orderProcessingDate,
    selectedAddress,
    orderProcessingType,
    orderTimeSelectValue,
  } = useSelector((state) => state.public);

  const match = useRouteMatch();
  const dispatch = useDispatch();

  // =========== START ==========
  console.log(orderProcessingType, "TYPE PAYMENTSLEECTOR");
  // =========== END ===========
  const [cookieOrderId, setCookieOrderId, removeCookieOrderId] = useCookies([
    "",
  ]);

  const couponAmount = promoCode ? parseFloat(totalDiscount) : 0.0;
  // Need to add deliveryCharge
  // const deliveryCharge = 0;
  const subTotal = getSubtotalPrice();
  const tax = getTotalTaxAmount();
  const discount = getTotalDiscount();
  const total = getTotalPrice();
  //---End From world pay component converted for SL meal balance payment---//

  const setOrder = () => {
    let pickupInstruction;
    if (
      enableRevenueCenter == 1 &&
      pickupTimebyRevenueCenter &&
      pickupTimebyRevenueCenter.length > 0
    ) {
      pickupInstruction = " Pickup Instruction: ";
      pickupTimebyRevenueCenter.map((i) => {
        pickupInstruction =
          pickupInstruction + i.revenueCenter + "=" + i.pickup + " ";
      });
    }
    const url =
      apiBaseURL + "/postpayment/setOrder/" + siteId + "?server_id=" + serverId;
    const d = new Date(orderProcessingDate);

    axios
      .post(url, {
        cartItems: cartItems,
        totalPriceItems: totalPriceItems,
        total: total,
        subTotal: subTotal,
        tips: tips,
        tax: tax,
        discount: discount,
        serviceCharge: orderProcessingType < 2 ? 0.0 : serviceCharge,
        promoCode: promoCode,
        pid: "meal_balance",
        order_id: !sessionStorage.getItem("order_id")
          ? sessionStorage.getItem("order_id")
          : 0,
        orderProcessingType:
          orderProcessingType == 0
            ? dineInDesc
            : orderProcessingType == 1
              ? pickupDesc
              : deliveryDesc,
        orderProcessingDate: {
          date: d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(),
          time: d.getHours() + ":" + d.getMinutes(),
        },
        selectedAddress: selectedAddress,
        instructions: sessionStorage.getItem("instuctions"),
        pickupTimebyRevenueCenter: pickupTimebyRevenueCenter,
        serverName:
          tableNumber && tableNumber != ""
            ? "eServer"
            : enableKiosk === 1
              ? "eKiosk"
              : "eCommerce",
        tableNumber: tableNumber,
      })
      .then((response) => {
        console.log(response);
        sessionStorage.setItem("order_id", response.data.order_id);
        setCookieOrderId("orderId", response.data.order_id, {
          path: "/",
        });
        sessionStorage.setItem("user_id", response.data.user_id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const payments = [
    "Meal Credit",
    //"Gift Cards",
    //"Rewards",
  ];

  return (
    <>
      <br />
      <Typography
        style={{
          fontWeight: 700,
          fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
        }}
        gutterBottom
      >
        Type of Payment
      </Typography>

      <div className={classes.root} style={{ "overflow-x": "visible" }}>
        <ImageList
          style={{
            width: "auto !important",
            "overflow-x": "visible",
            margin: "0",
          }}
          className={classes.gridList}
          cols={2.2}
        >
          {payments.map((payment) => {
            return (
              <ImageListItem
                className="content"
                key={payment}
                style={{
                  height: "auto",
                  margin: "0rem 6px",
                  padding: "1rem 0rem",
                  width: "auto !important",
                  paddingLeft: "0",
                }}
              >
                <Box
                  style={{
                    cursor: "pointer",
                    backgroundColor: buttonColor,
                    fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    color: buttonText,
                    borderColor: buttonColor,
                    width: enableKiosk === "0" ? "inherit" : "200px",
                    height: enableKiosk === "0" ? "inherit" : "120px",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100px"
                  padding="1rem"
                  border="1px solid #bebebe"
                  borderRadius="5px"
                  onClick={() => {
                    if (
                      cartItems &&
                      cartItems.length > 0 &&
                      orderProcessingDate
                    ) {
                      //Commented because it cause issue during checkout even if time was already selected
                      // if (!selectedTime) {
                      //   Swal.fire({
                      //     icon: "error",
                      //     title: "Oops...",
                      //     text: "Please select a time before submitting your order.",
                      //     showConfirmButton: "OK",
                      //     // timer: 4000,
                      //     // timerProgressBar: true,
                      //     // position: 'bottom-end',
                      //   });
                      //   return;
                      // }
                      setOrder();
                      setOpenUserCards(true);
                      dispatch({
                        type: "SET_SELECTED_MODE_PAYMENT",
                        payload: payment,
                      });
                    } else {
                      if (!orderProcessingDate) {
                        alert("Please provide Service Option details!");
                      } else {
                        alert("Your Check is Empty!");
                      }
                    }
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography
                      style={{
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                    >
                      {payment}
                    </Typography>
                    {payment === "Meal Credit" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "CARDICONS" + ".svg"}
                        style={{
                          maxWidth: "70px",
                          margin: "0px",
                        }}
                      />
                    )}
                    {payment === "Gift Cards" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "GIFTCARD" + ".webp"}
                        style={{
                          maxWidth: "70px",
                          margin: "0px",
                        }}
                      />
                    )}
                    {payment === "Rewards" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "COIN" + ".webp"}
                        style={{
                          maxWidth: "70px",
                          margin: "0px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
        <PaymentNested />
      </div>
      {enableQRFooter == 1 ? <CustomerSatisfactionQRCode /> : null}
    </>
  );
};

export const SelectCreditCardDrawerSL = ({ open, setOpen }) => {
  const [userCards, setUserCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const mBal = Math.abs(mealBalanceDollars / 100);
  const mBalance = mBal.toFixed(2);
  const { user } = useSelector((state) => state.public);
  const { selectedPayment } = useSelector((state) => state.cart);
  const { orderTimeSelectValue } = useSelector((state) => state.public);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;

  useEffect(() => {
    const url =
      loginCustomerNumber == 1
        ? apiBaseURL +
          "/customer/login-customer-number/" +
          serverId +
          "/" +
          siteId +
          "/" +
          firstName +
          "/" +
          roomNumber
        : apiBaseURL +
          "/customer/login/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId +
          "/" +
          firstName +
          "/" +
          roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(
            customer.CreditLimit - customer.AvailableCredit
          );
          //change MealDollrar to Mealcount
          dispatch(
            setSelectedAddress({
              first_name: customer.FirstName,
              last_name: customer.LastName,
              phone: customer.Phone,
              email: customer.Email ? customer.Email : "",
              address: customer.Address1,
              address2: customer.Address2,
              city: customer.City,
              zipCode: customer.Zip,
            })
          );
        }
      })
      .catch((error) => {});
  }, []);

  const sendOrder = () => {
    setLoading(true);
    const url =
      apiBaseURL +
      "/postpayment/send_econnect_sl/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      sessionStorage.getItem("order_id");
    axios
      .get(url)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          localStorage.clear();
          sessionStorage.removeItem("order_id");
          sessionStorage.removeItem("table");

          toast.success(`Your Order has been Completed! Thank You.`);
          if (enableRevenueCenter == 1) {
            history.push("/welcome");
          } else {
            history.push("/menu");
          }
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      <Modal
        show={showModal}
        style={{ zIndex: "99999" }} // corrected style object
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-container"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Success!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
            }}
            component="h4"
            variant="subtitle1"
          >
            Your Order has been Completed! Thank You.
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Grid container justifyContent="center" className="content">
            <Button
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              style={{
                textTransform: "none",
                padding: "0rem 1rem",
                borderRadius: "10px",
                border: "1px solid #bebebe",
                backgroundColor: buttonColor,
                fontSize: "large",
                width: "50%",
              }}
              onClick={() => {
                setOpen(false);
                setShowModal(false);
                // setTimeout(() => {
                //   if (enableRevenueCenter == 1) {
                //     history.push("/welcome");
                //   } else {
                //     history.push("/menu");
                //   }
                // }, 500);
              }}
            >
              OK
            </Button>
          </Grid>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box id="target" padding="1rem" minHeight="80vh" position="relative">
        <Box height="40px" />
        <Typography
          style={{
            fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
          }}
          className="content"
          component="div"
          align="center"
        >
          Hey {firstName}, your
        </Typography>
        <Typography
          className="content"
          style={{
            fontWeight: 700,
            "text-align": "center",
            fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
          }}
        >
          {/*selectedPayment*/}
        </Typography>
        {userCards.map((card) => {
          return (
            <Box
              borderRadius="10px"
              border="1px solid #bebebe"
              padding="1rem 1rem"
              margin="1rem 0rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              overflow="auto"
              onClick={() => {
                dispatch(setModeOfPayment(card));
                setOpen(false);
              }}
            >
              <Typography className="content">{card.MaskedAccount}</Typography>
            </Box>
          );
        })}

        {selectedPayment == "Meal Credit" && (
          <div>
            <Typography
              component="div"
              align="center"
              style={{
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
              }}
            >
              <b>Meal Credit Available: </b>
              <b>{mealBalance}</b>{" "}
            </Typography>
            <Typography
              component="div"
              align="center"
              style={{
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
              }}
            >
              <b>Current Balance: </b>
              <b>${mBalance}</b>{" "}
            </Typography>
            <br></br>
            <Grid container justifyContent="center" className="content">
              <Button
                fullWidth
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{
                  textTransform: "none",
                  padding: "0rem 1rem",
                  borderRadius: "10px",
                  border: "1px solid #bebebe",
                  backgroundColor: buttonColor,
                  fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                  width: "50%",
                }}
                onClick={() => {
                  sendOrder();
                }}
              >
                Submit Order
              </Button>
            </Grid>
          </div>
        )}
      </Box>
    </Drawer>
  );
};
