import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Cart } from "../cart";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setTotalPrice } from "redux/cartReducer/actions";

const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "0 1rem",
  width: "850px",
  marginLeft: "auto",
  marginRight: "auto",
};
const buttonStyle = {
  flex: 1,
  height: "5.5rem",
  width: "100%",
};
const buttonWithGapStyle = {
  ...buttonStyle,
  marginRight: ".5rem",
};

export default function BottomAppBar() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { cartItems } = useSelector((state) => state.cart);

  const isNumber = (value) => {
    return typeof value === "number";
  };

  useEffect(() => {
    const checkCart = localStorage.getItem(`ajsliders_cart`);
    if (checkCart) {
      dispatch({ type: "SET_CART", payload: JSON.parse(checkCart) });
    } else {
      dispatch({ type: "SET_CART", payload: [] });
    }
  }, []);

  useEffect(() => {
    if (cartItems.length) {
      localStorage.setItem(`ajsliders_cart`, JSON.stringify(cartItems));
      let totalItems = 0;
      let totalPrice = 0;
      cartItems.forEach((item) => {
        let mod_price = 0;

        const quantity = item.quantity || 1; // Default to 1 if quantity is missing
        const itemPrice = isNumber(item.DefaultPrice)
          ? item.DefaultPrice / 100
          : parseFloat(item.DefaultPrice) || 0; // Ensure a valid price

        item.modifiers.forEach((mod) => (mod_price += mod.DefaultPrice));

        totalItems += quantity;
        totalPrice += quantity * itemPrice + mod_price / 100;
      });
      dispatch(setTotalPrice(totalPrice));
    } else {
      dispatch(setTotalPrice(0));
      localStorage.removeItem(`ajsliders_cart`);
    }
  }, [cartItems]);

  return (
    <React.Fragment>
      {enableKiosk === "1" ? (
        <AppBar
          position="fixed"
          sx={{
            background: "transparent",
            top: "auto",
            bottom: 0,
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Box sx={containerStyle}>
              <Button
                variant="contained"
                style={{
                  ...buttonWithGapStyle,
                  backgroundColor: "#A20F1C",
                  fontSize: "1.35rem",
                  width: "500px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Cancel Order
                <CancelOutlined
                  fontSize="large"
                  style={{
                    transform: "scale(1)",
                    width: "2em",
                    height: "2em",
                    padding: "9px",
                    marginLeft: "1.25rem",
                    backgroundColor: colorBackground,
                    color: "black",
                    borderRadius: "1em",
                  }}
                />
              </Button>

              <Button
                color="success"
                variant="contained"
                style={{
                  ...buttonStyle,
                  backgroundColor: "#3D5309",
                  fontSize: "1.35rem",
                  width: "500px",
                  textAlign: "left",
                  lineHeight: "1.2",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => {
                  history.push(
                    sessionStorage.getItem("isResident") === "1"
                      ? "/checkout-sl"
                      : "/checkout"
                  );
                }}
              >
                Proceed to Checkout
                <Cart
                  style={{
                    backgroundColor: colorBackground,
                  }}
                />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      ) : null}
    </React.Fragment>
  );
}
