import React, { useState, useEffect } from "react";
import { Typography, useMediaQuery, IconButton } from "@material-ui/core";
import { scroller } from "react-scroll";
import { NavBar, LowerNav } from "../navigation/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setLoading } from "../../../redux/publicReducer/actions";
import axios from "axios";
import { MenuNavigation } from "../components/MenuNavigation";
import BlankSpaceTen from "../components/BlankSpaceTen";
import { Footer } from "../components/Footer";
import { OrderSlider } from "../components/OrderSlider";
import { Grid } from "@mui/material";
import { removeExpiredItems } from "../../../redux/cartReducer/actions";
import NavMenu from "../components/NavMenu";
import BottomAppBar from "../components/BottomAppBar";
import BackArrow from "../../../components/scroll/BackArrow";
import LogoCenter from "../components/LogoCenter";
import BottomMenuNav from "../components/BottomMenuNav";
import DrawerRight from "../components/DrawerRight";
import EKioskNav from "../components/EKioskNav";
import ScrollToDown from "components/scroll/ScrollToDown";
import ScrollToUp from "components/scroll/ScrollToUp";
import LogoutIcon from "components/scroll/LogoutIcon";
import BarTop from "../components/BarTop";
import BarBottom from "../components/BarBottom";
import FontSizeChanger from "react-font-size-changer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const enableSL = parseInt(process.env.REACT_APP_ENABLE_SL);
const enableKiosk = parseInt(process.env.REACT_APP_ENABLE_EKIOSK);

export const RestaurantHome = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [menuData, setMenuData] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const { search } = useSelector((state) => state.public);
  const [showLogoutIcon, setShowLogoutIcon] = useState(true); // State to control visibility of LogoutIcon
  const [updateTime, setUpdateTime] = useState("");
  const [chosenCategory, setChosenCategory] = useState("");
  const [show, setShow] = useState(true);
  const buttonClose = () => setShow(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categories, setCategories] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };
  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const getMenus = async () => {
      try {
        const { data } = await axios.get(
          `${apiBaseURL}/items/menu/${serverId}/${siteId}`
        );

        // To Be Deleted once the image added to the response API
        setMenuData(data);
      } catch (error) {
        console.error("Error fetching menu data", error);
      }
    };

    getMenus();
  }, []);

  useEffect(() => {
    dispatch(removeExpiredItems());
    const intervalId = setInterval(() => {
      dispatch(removeExpiredItems());
    }, 60000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (state?.category && categories.length) {
      setChosenCategory(state.category.DepartmentID);
      scroller.scrollTo(state.category.DepartmentID, {
        duration: 1500,
        delay: 300,
        smooth: true,
        offset: -110,
      });
    }
  }, [categories]);

  useEffect(() => {
    if (search && search !== "") {
      categories.forEach((category) => {
        const element = document.getElementById(category.DepartmentID);
        if (element) {
          const images = element.getElementsByTagName("img");
          element.style.display = images.length < 1 ? "none" : "block";
        }
      });
    } else {
      categories.forEach((category) => {
        const element = document.getElementById(category.DepartmentID);
        if (element) {
          element.style.display = "";
        }
      });

      scroller.scrollTo(chosenCategory, {
        duration: 1500,
        delay: 0,
        smooth: true,
        offset: -110,
      });
    }
  }, [search, categories, chosenCategory]);

  useEffect(() => {
    dispatch(setLoading(true));
    const url =
      apiBaseURL +
      "/items/all/" +
      siteId +
      "?server_id=" +
      serverId +
      "&customerNumber=" +
      getCookie("customerNumber");
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (availableProducts !== response.data) {
          setAvailableProducts(response.data);
        }
      })
      .catch((error) => {});
    dispatch(setLoading(false));
  }, [updateTime]);

  useEffect(() => {
    const url =
      apiBaseURL + "/items/categories/" + siteId + "?server_id=" + serverId;
    //const url = "/servingintel_api/public/items/categories";
    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {});
  }, [updateTime]);

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    const isResident = sessionStorage.getItem("isResident");
    if (isResident === "0") {
      setShowLogoutIcon(false);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="OrderNow">
        {enableSL === 0 ? <MenuNavigation /> : <NavMenu />}
        {enableKiosk === 0 && enableSL === 0 ? <NavBar /> : null}
        {enableKiosk === 0 && enableSL === 1 ? <LogoCenter /> : null}
        {enableKiosk === 0 && enableSL === 0 ? <OrderSlider /> : null}
        {enableKiosk === 1 && enableSL === 0 ? <EKioskNav /> : null}
        {enableKiosk === 0 && enableSL === 1 ? <BarTop /> : null}
        {enableKiosk === 0 && enableSL === 1 ? <BarBottom /> : null}
        {enableKiosk === 0 && enableSL === 1 ? <ScrollToDown /> : null}
        {enableKiosk === 0 && enableSL === 1 ? <ScrollToUp /> : null}
        {enableKiosk === 1 && enableSL === 1 ? <BottomAppBar /> : null}
        {enableSL === 1 && (showLogoutIcon ? <LogoutIcon /> : <BackArrow />)}
        {enableKiosk === 0 && (enableSL === 0 ? null : <DrawerRight />)}
        <BlankSpaceTen />
        {enableKiosk === 0 && enableSL === 1 ? (
          <FontSizeChanger
            targets={["#target .content"]}
            onChange={(element, newValue, oldValue) => {
              console.log(element, newValue, oldValue);
            }}
            options={{
              stepSize: 2,
              range: 5,
            }}
            customButtons={{
              up: (
                <AppBar
                  position="fixed"
                  sx={{
                    width: "95px",
                    boxShadow: 0,
                    top: "auto",
                    bottom: 237.5,

                    backgroundColor: "transparent",
                    marginBottom: "45px",
                  }}
                >
                  <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton>
                      <Fab
                        color="primary"
                        aria-hidden="true"
                        sx={{ backgroundColor: "#3D5309" }}
                      >
                        <AddCircleOutlineIcon />
                      </Fab>
                    </IconButton>
                  </Toolbar>
                </AppBar>
              ),
              down: (
                <AppBar
                  position="fixed"
                  sx={{
                    width: "95px",
                    boxShadow: 0,
                    top: "auto",
                    bottom: 180,

                    backgroundColor: "transparent",
                    marginBottom: "45px",
                  }}
                >
                  <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton>
                      <Fab
                        color="primary"
                        aria-hidden="true"
                        sx={{ backgroundColor: "#765F00" }}
                      >
                        <RemoveCircleOutlineIcon />
                      </Fab>
                    </IconButton>
                  </Toolbar>
                </AppBar>
              ),
            }}
          />
        ) : null}
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            margin: "40px auto",
            padding: "16px",
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 2, sm: 4, lg: 16 }}
          >
            {menuData.map((menu, index) => (
              <Grid
                item
                xs={2}
                sm={2}
                lg={4}
                key={index}
                sx={{ cursor: "pointer" }}
                className="main-menus"
                onClick={() => {
                  history.push("/menu/" + menu.MenuName.replace(/ /g, "-"));
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px",
                    overflow: "hidden",
                  }}
                >
                  {enableKiosk === 0 && enableSL === 1 ? (
                    <img
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                        transition: "all 1.5s",
                      }}
                      src={`${siteMedSrc}${menu.MenuName.replace(
                        ",",
                        ""
                      )}.webp`}
                      alt={menu.MenuName}
                      onError={(error) =>
                        (error.target.src = siteMedSrc + "empty-img.webp")
                      }
                    />
                  ) : (
                    <img
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                        transition: "all 1.5s",
                      }}
                      src={`${siteMedSrc}${menu.MenuName.replace(/ /g, "-")
                        .replace(",", "")
                        .toLowerCase()}.webp`}
                      alt={menu.MenuName}
                      onError={(error) =>
                        (error.target.src = siteMedSrc + "empty-img.webp")
                      }
                    />
                  )}
                </div>
                <div style={{ marginBottom: "40px" }}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      lineHeight: 1.1,
                      fontSize: "1.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    {menu.MenuName}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      {enableKiosk === 0 ? enableSL === 0 ? <Footer /> : null : null}
      {enableKiosk === 0 && enableSL === 1 ? (
        <BottomMenuNav
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        />
      ) : null}
    </React.Fragment>
  );
};
