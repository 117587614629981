import React, { useState, useEffect, Fragment } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Toolbar,
  Paper,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import DeleteIcon from "@material-ui/icons/Delete";
import { setCart } from "../../../redux/cartReducer/actions";

export const DeleteAllCartItems = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Removing All Items</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          Are you sure you want to delete all items in your cart?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRAdius: "15px" }}
          onClick={() => dispatch(setCart([]))}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CartPage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { cartItems, totalPriceItems } = useSelector((state) => state.cart);
  const [totalItems, setTotalItems] = useState(0);
  const [chosen, setChosen] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const _itemsInCart = localStorage.getItem(
      match.params.restaurantId + "_cart"
    );
    if (_itemsInCart) {
      dispatch({ type: "SET_CART", payload: JSON.parse(_itemsInCart) });
    }
  }, []);

  useEffect(() => {
    if (cartItems.length) {
      localStorage.setItem(
        `${match.params.restaurantId}_cart`,
        JSON.stringify(cartItems)
      );
      let total = 0;
      cartItems.forEach((item) => {
        total += item.quantity;
      });
      setTotalItems(total);
    } else {
      setTotalItems(0);
      localStorage.removeItem(`${match.params.restaurantId}_cart`);
    }
  }, [cartItems]);

  useEffect(() => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += item.price * item.quantity;
      item.modifiers?.forEach((modifier) => {
        totalPrice += modifier.price * item.quantity;
      });
    });
    dispatch({ type: "SET_TOTAL_PRICE", payload: totalPrice });
  }, [cartItems]);

  return (
    <Container>
      <DeleteAllCartItems open={open} setOpen={setOpen} />
      <Grid alignItems="center" container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Paper
            style={{
              position: "fixed",
              top: "0px",
              left: "0px",
              width: "100%",
              padding: "1rem",
              zIndex: 10,
            }}
            square
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 10,
              }}
            >
              <IconButton
                style={{ zIndex: 100 }}
                onClick={() => history.goBack()}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
                style={{ zIndex: 100 }}
              >
                <DeleteForeverIcon color="secondary" />
              </IconButton>
            </div>
          </Paper>
          <Toolbar />
          <Toolbar />
          <Typography
            variant="h5"
            style={{ fontWeight: 800, margin: "1rem 0rem" }}
          >
            My Order
          </Typography>
          <Grid container spacing={1}>
            {cartItems.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={chosen === index ? 10 : 12}
                    onClick={() => {
                      if (chosen != index) {
                        setChosen(index);
                      } else {
                        setChosen(null);
                      }
                    }}
                    style={{ cursor: "pointer", margin: "1rem 0rem" }}
                  >
                    <div
                      style={{
                        padding: "1rem",
                        borderRadius: "15px",
                        backgroundColor: "#fcfcfc",
                        marginLeft: chosen === index && "-100px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <div style={{ marginRight: "1rem" }}>
                          <img
                            src={item.image}
                            style={{ maxWidth: "100px", borderRadius: "10px" }}
                          />
                        </div>
                        <div style={{ margin: "0px 12px 0px 6px" }}>
                          <Typography
                            style={{ fontWeight: 800 }}
                            variant="subtitle2"
                          >
                            {item.productName}
                          </Typography>
                          {/* <Typography
                            variant="caption"
                            style={{ color: "gray" }}
                          >
                            {item?.description}
                          </Typography> */}
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 800 }}
                          >
                            $ {numberWithCommas(item.price)}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "auto",
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if (item.quantity > 1) {
                                const newCartItems = cartItems.map(
                                  (cartItem) => {
                                    if (cartItem === item) {
                                      return {
                                        ...item,
                                        quantity: item.quantity - 1,
                                      };
                                    }

                                    return cartItem;
                                  }
                                );

                                dispatch({
                                  type: "SET_CART",
                                  payload: newCartItems,
                                });
                              } else {
                                const filterCartItems = cartItems
                                  .map((i) => i)
                                  .filter((j) => j !== item);
                                dispatch({
                                  type: "SET_CART",
                                  payload: filterCartItems,
                                });
                              }
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          {item.quantity}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              const newCartItems = cartItems.map((i) => {
                                if (i === item) {
                                  return { ...i, quantity: i.quantity + 1 };
                                }
                                return i;
                              });

                              dispatch({
                                type: "SET_CART",
                                payload: newCartItems,
                              });
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </div>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          {item?.modifiers?.length > 0 && (
                            <Typography>Addons</Typography>
                          )}
                          {item?.modifiers?.map((modifier) => {
                            return (
                              <Typography
                                style={{
                                  fontSize: "0.7rem",
                                  color: "gray",
                                }}
                              >
                                {modifier.price
                                  ? `${modifier.name} + $ ${numberWithCommas(
                                      modifier.price
                                    )}`
                                  : `${modifier.name}`}
                              </Typography>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  {chosen === index && (
                    <Grid item xs={2}>
                      <div
                        style={{
                          margin: "1rem 0rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // padding: "1rem",
                          borderRadius: "15px",
                          backgroundColor: "#FFCCCB",
                          // height: "100%",
                        }}
                        onClick={() => {
                          let deleteCartItems = cartItems
                            .map((cart) => cart)
                            .filter((i) => i !== item);
                          dispatch(setCart(deleteCartItems));
                        }}
                      >
                        <IconButton>
                          <DeleteIcon fontSize="default" color="secondary" />
                        </IconButton>
                      </div>
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid>
          <div
            style={{
              bottom: 0,
              left: 0,
              marginTop: "auto",
              padding: "1rem",
            }}
          >
            <Toolbar />
            <Paper
              style={{
                position: "fixed",
                bottom: "0px",
                left: "0px",
                width: "100%",
                padding: "1rem",
              }}
              square
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Typography style={{ color: "gray" }} variant="subtitle2">
                    Total Price
                  </Typography>
                  <Typography variant="h4" style={{ fontWeight: 800 }}>
                    $ {numberWithCommas(totalPriceItems)}
                  </Typography>
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ borderRadius: "20px" }}
                    onClick={() =>
                      history.push(`/${match.params.restaurantId}/checkout`)
                    }
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
